const initializeLocalStorage = () => {
  // Define the objects to save in local storage
  const storageData = {
    moodTracker: { '0-1': 2, '0-2': 2 },
    couponHistory: [
      { date: '12/16/2024', coupons: 16 },
      { date: '12/16/2024', coupons: 17 },
      { date: '12/16/2024', coupons: 13 },
      { date: '12/16/2024', coupons: 13 },
      { date: '12/16/2024', coupons: 11 },
      { date: '12/16/2024', coupons: 10 },
      { date: '12/16/2024', coupons: 11 },
      { date: '12/16/2024', coupons: 8 },
      { date: '12/16/2024', coupons: 8 },
      { date: '12/16/2024', coupons: 9 },
      { date: '12/16/2024', coupons: 8 },
    ],
    ocdTriggers: [
      {
        date: '12/16/2024',
        trigger: 'Someone sneezed near me.',
        thought: '"I might catch a serious illness from their germs."',
        compulsion:
          'Avoided touching my face and sanitized my hands repeatedly.',
        relief: 'Listened to calming music.',
        distress: '😐',
      },
      {
        date: '12/16/2024',
        trigger: 'Touched a door handle in a public restroom.',
        thought: '"I’ll get sick if I don’t wash my hands immediately."',
        compulsion: 'Washed hands repeatedly for 5 minutes.',
        relief: 'Focused on deep breathing after leaving the restroom.',
        distress: '😟',
      },
      {
        date: '12/16/2024',
        trigger: 'Left the house without checking the stove.',
        thought: '"The house will burn down, and it will be my fault."',
        compulsion: 'Returned home to check the stove twice.',
        relief: 'Talked to a friend about unrelated topics.',
        distress: '😢',
      },
      {
        date: '12/16/2024',
        trigger: 'Accidentally stepped on a crack in the sidewalk.',
        thought: '"Something bad will happen to my family because of this."',
        compulsion:
          'Stepped on another crack intentionally to ‘cancel it out.’',
        relief: 'Counted backward from 100.',
        distress: '🙂',
      },
    ],
    exposureHierarchy: [
      {
        id: 1734362331520,
        title: 'Passing a cemetery while walking alone at night. ',
        distress: '4',
        completed: false,
      },
      {
        id: 1734362347848,
        title:
          'Leaving a prayer incomplete or skipping a ritualistic behavior.',
        distress: '4',
        completed: false,
      },
      {
        id: 1734362363211,
        title:
          'Eating food prepared by someone else without knowing if they washed their hands.',
        distress: '3',
        completed: false,
      },
      {
        id: 1734362378882,
        title: 'Throwing away an item that might someday be ‘useful.’',
        distress: '3',
        completed: false,
      },
      {
        id: 1734362396604,
        title: 'Shaking hands with a stranger at a social event.',
        distress: '3',
        completed: false,
      },
      {
        id: 1734362408267,
        title: 'Sitting on a chair that someone else recently used.',
        distress: '2',
        completed: true,
      },
      {
        id: 1734362419100,
        title: 'Driving on a highway with no way to quickly pull over. ',
        distress: '1',
        completed: true,
      },
    ],
    'Avoiding cracks on sidewalks': [
      [
        { time: 0, level: 60 },
        { time: 5, level: 60 },
        { time: 10, level: 65 },
        { time: 15, level: 70 },
        { time: 20, level: 65 },
        { time: 25, level: 60 },
        { time: 30, level: 55 },
        { time: 35, level: 50 },
        { time: 40, level: 40 },
        { time: 45, level: 35 },
        { time: 50, level: 30 },
        { time: 55, level: 25 },
      ],
      [
        { time: 0, level: 70 },
        { time: 5, level: 65 },
        { time: 10, level: 60 },
        { time: 15, level: 55 },
        { time: 20, level: 55 },
        { time: 25, level: 45 },
        { time: 30, level: 40 },
        { time: 35, level: 35 },
        { time: 40, level: 30 },
        { time: 45, level: 25 },
        { time: 50, level: 25 },
        { time: 55, level: 20 },
      ],
    ],
    'Arranging items symmetrically': [
      [
        { time: 0, level: 85 },
        { time: 5, level: 75 },
        { time: 10, level: 70 },
        { time: 15, level: 60 },
        { time: 20, level: 60 },
        { time: 25, level: 55 },
        { time: 30, level: 45 },
        { time: 35, level: 45 },
        { time: 40, level: 40 },
        { time: 45, level: 35 },
        { time: 50, level: 35 },
        { time: 55, level: 20 },
      ],
      [
        { time: 0, level: 70 },
        { time: 5, level: 70 },
        { time: 10, level: 65 },
        { time: 15, level: 55 },
        { time: 20, level: 55 },
        { time: 25, level: 50 },
        { time: 30, level: 45 },
        { time: 35, level: 40 },
        { time: 40, level: 50 },
        { time: 45, level: 35 },
        { time: 50, level: 30 },
        { time: 55, level: 20 },
      ],
    ],
  };

  // Iterate through the data and save it to local storage if not already initialized
  const isInitialized = localStorage.getItem(`initialized`);

  if (!isInitialized) {
    Object.entries(storageData).forEach(([key, value]) => {
      localStorage.setItem(key, JSON.stringify(value));
    });
    localStorage.setItem(`initialized`, 'true');
    window.location.reload();
  }
};

export default initializeLocalStorage;
