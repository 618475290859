// src/index.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Home from './Home';
import OCDTools from './OCDTools';
import TOS from './TOS';
import Privacy from './Privacy';
import Donate from './Donate';
import Dashboard from './Dashboard';
import Settings from './Settings';
import SUDSImport from './SUDSImport';
import OCDNews from './OCDNews';
import OCDTriggerTracker from './OCDTriggerTracker';
import MoodTracker from './MoodTracker';
import ExposureHierarchyBuilder from './ExposureHierarchyBuilder';
import AgendaCalendar from './AgendaCalendar';
import LoginScreen from './LoginScreen';
import SuccessPage from './SuccessPage';
import Cancel from './Cancel'; // Adjust the import path as necessary
import TherapistRegistration from './TherapistRegistration';
import ClientRegistration from './ClientRegistration';
import Pricing from './Pricing';
import TherapistDashboard from './TherapistDashboard';
import ClientDashboard from './ClientDashboard';
import Journal from './Journal';
import MoodTrackerClient from './MoodTrackerClient';
import ExposureHierarchyBuilderClient from './ExposureHierarchyBuilderClient';
import OCDTriggerTrackerClient from './OCDTriggerTrackerClient';
import SUDSImportClient from './SUDSImportClient';
import Coupon from './Coupon';
import LogsDisplay from './LogsDisplay';
import OurVision from './OurVision';
import HowToUseOCD from './HowToUseOCD';
import HowToUseTherapist from './HowToUseTherapist';
import GetADemo from './GetADemo';
import YaleBrownForm from './YaleBrownForm';
import GAD7 from './GAD7';
import PHQ9 from './PHQ9';
import GAD7Statistics from './GAD7Statistics'; // Import your new page component
import PHQ9Statistics from './PHQ9Statistics'; // Import your new page component
import YBOCSStatistics from './YBOCSStatistics';


import PostSUDS from './PostSUDS';
import PostOCDExperts from './PostOCDExperts';
import { AudioProvider } from './AudioContext'; 



function App() {
  const [therapistId, setTherapistId] = useState(null);
  return (
    <>
    <Router>
    <AudioProvider>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/tos" element={<TOS />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/tools" element={<OCDTools />} />
        <Route path="/sudsimport" element={<SUDSImport />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/donate" element={<Donate />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/ocdnews" element={<OCDNews />} />
        <Route path="/trigger" element={<OCDTriggerTracker />} />
        <Route path="/mood" element={<MoodTracker />} />
        <Route path="/hierarchy-builder" element={<ExposureHierarchyBuilder />} />
        <Route path="/agenda" element={<AgendaCalendar />} />
        <Route path="/suds-tracker-manage-ocd-anxiety" element={<PostSUDS />} />
        <Route path="/50-influential-experts-shaping-ocd" element={<PostOCDExperts />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/therapistdashboard" element={<TherapistDashboard therapistId={therapistId} />} />
        <Route path="/client-dashboard" element={<ClientDashboard />} />
        <Route path="/login" element={<LoginScreen setTherapistId={setTherapistId} />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/cancel" element={<Cancel />} />
        <Route path="/registerastherapist" element={<TherapistRegistration />} />
        <Route path="/registerasclient" element={<ClientRegistration />} />
        <Route path="/journal" element={<Journal />} />
        <Route path="/client-mood-tracker" element={<MoodTrackerClient />} />
        <Route path="/client-exposure-tracker" element={<ExposureHierarchyBuilderClient />} />
        <Route path="/client-trigger-tracker" element={<OCDTriggerTrackerClient />} />
        <Route path="/client-suds-tracker" element={<SUDSImportClient />} />
        <Route path="/coupon" element={<Coupon />} />
        <Route path="/admin-dashboard" element={<LogsDisplay />} />
        <Route path="/ourvision" element={<OurVision />} />
        <Route path="/how-to-ocd" element={<HowToUseOCD />} />
        <Route path="/how-to-therapist" element={<HowToUseTherapist />} />
        <Route path="/get-a-demo" element={<GetADemo />} />
        <Route path="/yale-brown" element={<YaleBrownForm />} />
        <Route path="/gad7" element={<GAD7 />} />
        <Route path="/phq9" element={<PHQ9 />} />
        <Route path="/gad7-statistics" element={<GAD7Statistics />} />
        <Route path="/phq9-statistics" element={<PHQ9Statistics />} />
        <Route path="/ybocs-statistics" element={<YBOCSStatistics />} />
        
      </Routes>
      </AudioProvider>
    </Router>

    </>
  );
}

export default App;
