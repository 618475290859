import React, { useEffect, useState } from 'react';
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { onAuthStateChanged } from 'firebase/auth';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'; // Import Recharts
import './YaleBrownForm.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';

const GAD7 = () => {
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [scores, setScores] = useState({ totalScore: 0 });

  const [answers, setAnswers] = useState({
    question1: 0,
    question2: 0,
    question3: 0,
    question4: 0,
    question5: 0,
    question6: 0,
    question7: 0,
  });

  const [scoreHistory, setScoreHistory] = useState([]); // Store the historical scores
  const currentUser = auth.currentUser; // Get the logged-in user
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          //console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Trigger calculateScores when answers state is updated
    calculateScores();
  }, [answers]);

  // Fetch the most recent form for the user when loading the page
useEffect(() => {
  const fetchForms = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'GAD7_assessment', currentUser.uid); // Reference to the user document
      const formsCollectionRef = collection(userDocRef, 'GAD7_forms'); // Sub-collection for the forms

      try {
        // Query the sub-collection to get all forms and order by timestamp
        const q = query(formsCollectionRef, orderBy('timestamp', 'asc')); // Use 'asc' to get the oldest forms first
        const querySnapshot = await getDocs(q);
        //console.log('lst form: ',querySnapshot);

        if (!querySnapshot.empty) {
          const formsData = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            formsData.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
            //console.log('formsData: ',formsData);
          });
          //console.log('formsData1: ',formsData);
          setScoreHistory(formsData); // Store the historical scores
          const mostRecentForm = formsData[formsData.length - 1]; // Get the most recent form
          setAnswers(mostRecentForm.answers || {}); // Set the most recent form's answers
          setScores(mostRecentForm.scores || { totalScore: 0 }); // Set the most recent form's scores
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    }
    setLoading(false); // Set loading to false once data is fetched
  };

  fetchForms(); // Fetch the most recent form and score
}, [currentUser]);

  const [successMessage, setSuccessMessage] = useState('');

  //const db = getFirestore();

  const handleChange = (e, key) => {
    const { value } = e.target;

    // Update the answers state
    setAnswers(prev => ({
      ...prev,
      [key]: parseInt(value, 10),
    }));
  };

  const calculateScores = () => {
    const obsessionKeys = [
      'question1',
      'question2',
      'question3',
      'question4',
      'question5',
      'question6',
      'question7',
    ];

    const totalScore = obsessionKeys.reduce(
      (sum, key) => sum + (answers[key] || 0),
      0
    );

    setScores({
      totalScore,
    });
  };

  // Recalculate the score whenever `answers` change
  useEffect(() => {
    calculateScores();
  }, [answers]);

  const handleSubmit = async e => {
    e.preventDefault();
    calculateScores();

    const saveData = async () => {
      // Reference the user's "GAD7_forms" sub-collection
      const userDocRef = doc(db, 'GAD7_assessment', currentUser.uid); // Reference to the user document
      const formsCollectionRef = collection(userDocRef, 'GAD7_forms'); // Sub-collection for the forms

      try {
        // Add the new form as a document in the user's sub-collection
        await addDoc(formsCollectionRef, {
          answers,
          scores,
          timestamp: new Date(), // Save the current date and time
        });

        alert('Your assessment has been saved successfully!');
        // Fetch the updated score history after saving the new form
      fetchForms(); // Fetch and update score history after saving the form
      } catch (error) {
        console.error('Error saving assessment:', error);
        alert('There was an error saving your assessment. Please try again.');
      }
    };

    const saveToLocalStorage = () => {
      localStorage.setItem(
        'GAD7_assessment',
        JSON.stringify({ answers, timestamp: new Date() })
      );
      alert('Your assessment has been saved!');
    };

    if (currentUser) {
      await saveData(); // Save to Firestore if logged in
    } else {
      saveToLocalStorage(); // Save to localStorage if not logged in
    }
  };

  const fetchForms = async () => {
  if (currentUser) {
    const userDocRef = doc(db, 'GAD7_assessment', currentUser.uid); // Reference to the user document
    const formsCollectionRef = collection(userDocRef, 'GAD7_forms'); // Sub-collection for the forms

    try {
      // Query the sub-collection to get all forms and order by timestamp
      const q = query(formsCollectionRef, orderBy('timestamp', 'asc'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const formsData = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          formsData.push({
            timestamp: data.timestamp.toDate(),
            totalScore: data.scores.totalScore,
          });
        });
        setScoreHistory(formsData); // Store the historical scores
        const mostRecentForm = formsData[formsData.length - 1];
        setAnswers(mostRecentForm.answers || {});
        setScores(mostRecentForm.scores || { totalScore: 0 });
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  }
};

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

if (loading) {
    return <div>Loading...</div>; // Show loading indicator while waiting for data
  }
  return (
    <>
      {NavbarComponent}
      <div className='yale-brown-form'>
        <h1>GAD-7 (General Anxiety Disorder-7)</h1>
        <p className='assessment-intro'>
          The <strong>GAD-7 (Generalized Anxiety Disorder-7)</strong> is a
          self-administered questionnaire used to assess the severity of
          generalized anxiety disorder (GAD) symptoms in individuals. It
          consists of 7 questions that focus on the frequency of specific
          anxiety-related symptoms over the past two weeks. The GAD-7 helps
          healthcare providers screen for generalized anxiety disorder and track
          symptom severity over time. It is widely used in clinical settings to
          assist in diagnosing GAD and monitoring the effectiveness of
          treatment.
        </p>
        <br />
        {/* Display message if not logged in */}
        {!isLoggedIn && (
          <div className='assessment-intro'>
          <h2>Historical GAD-7 Scores</h2>
            <p>This feature is only available for registered users.</p>
          </div>
        )}

        {isLoggedIn && (
        <div className='assessment-intro'>
          <h2>Historical GAD-7 Scores</h2>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={scoreHistory}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                />
                <YAxis />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='totalScore'
                  stroke='#8884d8'
                  name='GAD-7 Scores'
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        )}
        <br />
        <p>
          How often have you been bothered by the following over the past 2
          weeks?
        </p>
        <form onSubmit={handleSubmit}>
          {[
            {
              key: 'question1',
              question: '1. Feeling nervous, anxious, or on edge',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question2',
              question: '2. Not being able to stop or control worrying',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question3',
              question: '3. Worrying too much about different things',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question4',
              question: '4. Trouble relaxing',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question5',
              question: "5. Being so restless that it's hard to sit still",
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question6',
              question: '6. Becoming easily annoyed or irritable',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question7',
              question: '7. Feeling afraid as if something awful might happen',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question8',
              question:
                'If you checked any problems, how difficult have they made it for you to do your work, take care of things at home, or get along with other people?',
              options: [
                { label: 'Not difficult at all', value: 0 },
                { label: 'Somewhat difficult', value: 1 },
                { label: 'Very difficult', value: 2 },
                { label: 'Extremely difficult', value: 3 },
              ],
            },
          ].map(({ key, question, options }) => (
            <div key={key} className='question-block'>
              <p>
                <strong>{question}</strong>
              </p>
              {options.map((option, index) => (
                <label key={index}>
                  <input
                    type='radio'
                    name={key} // Group radio buttons
                    value={option.value} // Correctly access option.value
                    checked={answers[key] === option.value} // Correctly check against option.value
                    onChange={e => handleChange(e, key)} // Handle change for radio buttons
                  />
                  {option.label} {/* Correctly access option.label */}
                </label>
              ))}
            </div>
          ))}
          <br />
          {isLoggedIn && <button type='submit'>Save Score & Assessment</button>}
        </form>
        <br />
        {successMessage && <p>{successMessage}</p>}

        <div className='score-container'>
          <div className='score-box'>
            <h2>GAD-7</h2>
            <p className='score-value'>{scores.totalScore}</p>
            <p className='score-category'>
              {scores.totalScore <= 4
                ? 'Minimal Anxiety'
                : scores.totalScore <= 9
                ? 'Mild Anxiety'
                : scores.totalScore <= 14
                ? 'Moderate Anxiety'
                : 'Severe Anxiety'}
            </p>
          </div>
          <div className='score-box' style={{ width: '250px' }}>
            <h2>GAD-7 Interpretation</h2>
            <p className='score-category' style={{ textAlign: 'left' }}>
              <strong>00–04:</strong> Minimal Anxiety
              <br />
              <strong>05–09:</strong> Mild Anxiety
              <br />
              <strong>10–14:</strong> Moderate Anxiety
              <br />
              <strong>15–21:</strong> Severe Anxiety
            </p>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default GAD7;
