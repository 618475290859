import React, { useEffect, useState } from 'react';
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { onAuthStateChanged } from 'firebase/auth';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'; // Import Recharts
import './YaleBrownForm.css';
import { useLocation } from 'react-router-dom';

import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import Footer from './Footer';

const YBOCSStatistics = () => {
  const location = useLocation();
  const clientId = location.state?.clientId; // Get client.id from state

  const [scoreHistory, setScoreHistory] = useState([]); // Store the historical scores
  const [Q1History, setQ1History] = useState([]);
  const [Q2History, setQ2History] = useState([]);
  const [Q3History, setQ3History] = useState([]);
  const [Q4History, setQ4History] = useState([]);
  const [Q5History, setQ5History] = useState([]);
  const [Q6History, setQ6History] = useState([]);
  const [Q7History, setQ7History] = useState([]);
  const [Q8History, setQ8History] = useState([]);
  const [Q9History, setQ9History] = useState([]);
  const [Q10History, setQ10History] = useState([]);

  const currentUser = auth.currentUser; // Get the logged-in user
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          //setUserRole(userDoc.data().role);
          //console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Fetch the most recent form for the user when loading the page
  useEffect(() => {
    const fetchForms = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'YBOCS_assessment', clientId); // Reference to the user document
        const formsCollectionRef = collection(userDocRef, 'YBOCS_forms'); // Sub-collection for the forms

        try {
          // Query the sub-collection to get all forms and order by timestamp
          const q = query(formsCollectionRef, orderBy('timestamp', 'asc')); // Use 'asc' to get the oldest forms first
          const querySnapshot = await getDocs(q);
          //console.log('lst form: ',querySnapshot);

          if (!querySnapshot.empty) {
            const formsData = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsData.push({
                timestamp: data.timestamp.toDate(),
                totalScore: data.scores.total,
                compulsions: data.scores.compulsions,
                obsessions: data.scores.obsessions,
              });
            });
            setScoreHistory(formsData); // Store the historical scores

            const formsQ1 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ1.push({
                timestamp: data.timestamp.toDate(),
                question1: data.answers.obsessiveThoughtsTime,
              });
            });
            setQ1History(formsQ1); // Store the historical scores

            const formsQ2 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ2.push({
                timestamp: data.timestamp.toDate(),
                question2: data.answers.obsessiveThoughtsInterference,
              });
            });
            setQ2History(formsQ2); // Store the historical scores

            const formsQ3 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ3.push({
                timestamp: data.timestamp.toDate(),
                question3: data.answers.obsessiveThoughtsDistress,
              });
            });
            setQ3History(formsQ3); // Store the historical scores

            const formsQ4 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ4.push({
                timestamp: data.timestamp.toDate(),
                question4: data.answers.obsessiveThoughtsResistance,
              });
            });
            setQ4History(formsQ4); // Store the historical scores

            const formsQ5 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ5.push({
                timestamp: data.timestamp.toDate(),
                question5: data.answers.obsessiveThoughtsControl,
              });
            });
            setQ5History(formsQ5); // Store the historical scores

            const formsQ6 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ6.push({
                timestamp: data.timestamp.toDate(),
                question6: data.answers.compulsiveBehaviorsTime,
              });
            });
            setQ6History(formsQ6); // Store the historical scores

            const formsQ7 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ7.push({
                timestamp: data.timestamp.toDate(),
                question7: data.answers.compulsiveBehaviorsInterference,
              });
            });
            setQ7History(formsQ7); // Store the historical scores

            const formsQ8 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ8.push({
                timestamp: data.timestamp.toDate(),
                question8: data.answers.compulsiveBehaviorsDistress,
              });
            });
            setQ8History(formsQ8); // Store the historical scores

            const formsQ9 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ9.push({
                timestamp: data.timestamp.toDate(),
                question9: data.answers.compulsiveBehaviorsResistance,
              });
            });
            setQ9History(formsQ9); // Store the historical scores

            const formsQ10 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ10.push({
                timestamp: data.timestamp.toDate(),
                question10: data.answers.compulsiveBehaviorsControl,
              });
            });
            setQ10History(formsQ10); // Store the historical scores
          }
        } catch (error) {
          console.error('Error fetching forms:', error);
        }
      }
      setLoading(false); // Set loading to false once data is fetched
    };

    fetchForms(); // Fetch the most recent form and score
  }, [currentUser]);

  const [successMessage, setSuccessMessage] = useState('');

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while waiting for data
  }
  return (
    <>
      <NavbarTherapist />
      <div className='yale-brown-form'>
        <h1>Y-BOCS (Yale–Brown Obsessive–Compulsive Scale) History</h1>
        <br />

        <div className='assessment-intro'>
          <h2>Historical Y-BOCS Scores</h2>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={scoreHistory}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 7, 15, 23, 31, 40]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 7:
                        return 'Subclinical (0–7)';
                      case 15:
                        return 'Mild (8–15)';
                      case 23:
                        return 'Moderate (16–23)';
                      case 31:
                        return 'Severe (24–31)';
                      case 40:
                        return 'Extreme (32–40)';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={(value, name) => {
                    if (name === 'Y-BOCS Scores') {
                      switch (true) {
                        case value <= 7:
                          return ['Subclinical', value];
                        case value <= 15:
                          return ['Mild', value];
                        case value <= 23:
                          return ['Moderate', value];
                        case value <= 31:
                          return ['Severe', value];
                        case value <= 40:
                          return ['Extreme', value];
                        default:
                          return [value];
                      }
                    }
                    // Return default for other dataKeys
                    return [value];
                  }}
                />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='totalScore'
                  stroke='#8884d8'
                  name='Y-BOCS Scores'
                />
                {/* Line for Compulsions */}
                <Line
                  type='monotone'
                  dataKey='compulsions'
                  stroke='#82ca9d'
                  name='Compulsions Scores'
                />

                {/* Line for Obsessions */}
                <Line
                  type='monotone'
                  dataKey='obsessions'
                  stroke='yellow'
                  name='Obsessions Scores'
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            1. Time occupied by obsessive thoughts
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q1History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return ['Less than 1 hr/day or occasional occurrence'];
                      case 2:
                        return ['1 to 3 hrs/day or frequent'];
                      case 3:
                        return [
                          'Greater than 3 and up to 8 hrs/day or very frequent occurrence',
                        ];
                      case 4:
                        return [
                          'Greater than 8 hrs/day or nearly constant occurrence ',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question1'
                  fill='#8884d8'
                  name='Time occupied by obsessive thoughts'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            2. Interference due to obsessive thoughts
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q2History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return [
                          'Slight interference with social or other activities, but overall performance not impaired',
                        ];
                      case 2:
                        return [
                          'Definite interference with social or occupational performance, but still manageable',
                        ];
                      case 3:
                        return [
                          'Causes substantial impairment in social or occupational performance',
                        ];
                      case 4:
                        return ['Incapacitating'];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question2'
                  fill='#8884d8'
                  name='Interference due to obsessive thoughts'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            3. Distress associated with obsessive thoughts
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q3History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return ['Not too disturbing'];
                      case 2:
                        return ['Disturbing, but still manageable'];
                      case 3:
                        return ['Very disturbing'];
                      case 4:
                        return ['Near constant and disabling distress'];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question3'
                  fill='#8884d8'
                  name='Distress associated with obsessive thoughts'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>4. Resistance against obsessions</h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q4History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Try to resist all the time'];
                      case 1:
                        return ['Try to resist most of the time'];
                      case 2:
                        return ['Make some effort to resist'];
                      case 3:
                        return [
                          'Yield to all obsessions without attempting to control them, but with some reluctance',
                        ];
                      case 4:
                        return [
                          'Completely and willingly yield to all obsessions',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question4'
                  fill='#8884d8'
                  name='Resistance against obsessions'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            5. Degree of control over obsessive thoughts
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q5History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Complete control'];
                      case 1:
                        return [
                          'Usually able to stop or divert obsessions with some effort and concentration',
                        ];
                      case 2:
                        return ['Sometimes able to stop or divert obsessions'];
                      case 3:
                        return [
                          'Rarely successful in stopping or dismissing obsessions, can only divert attention with difficulty',
                        ];
                      case 4:
                        return [
                          'Obsessions are completely involuntary, rarely able to even momentarily alter obsessive thinking.',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question5'
                  fill='#8884d8'
                  name='Degree of control over obsessive thoughts'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            6. Time spent performing compulsive behaviors
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q6History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return [
                          'Less than 1 hr/day, or occasional performance of compulsive behaviors',
                        ];
                      case 2:
                        return [
                          'From 1 to 3 hrs/day, or frequent performance of compulsive behaviors',
                        ];
                      case 3:
                        return [
                          'More than 3 and up to 8 hrs/day, or very frequent performance of compulsive behaviors',
                        ];
                      case 4:
                        return [
                          'More than 8 hrs/day, or near constant performance of compulsive behaviors (too numerous to count)',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question6'
                  fill='#8884d8'
                  name='Time spent performing compulsive behaviors'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            7. Interference due to compulsive behaviors
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q7History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return [
                          'Slight interference with social or other activities, but overall performance not impaired',
                        ];
                      case 2:
                        return [
                          'Definite interference with social or occupational performance, but still manageable',
                        ];
                      case 3:
                        return [
                          'Causes substantial impairment in social or occupational performance',
                        ];
                      case 4:
                        return ['Incapacitating'];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question7'
                  fill='#8884d8'
                  name='Interference due to compulsive behaviors'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            8. Distress associated with compulsive behavior
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q8History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['None'];
                      case 1:
                        return [
                          'Only slightly anxious if compulsions prevented',
                        ];
                      case 2:
                        return [
                          'Anxiety would mount but remain manageable if compulsions prevented',
                        ];
                      case 3:
                        return [
                          'Prominent and very disturbing increase in anxiety if compulsions interrupted',
                        ];
                      case 4:
                        return [
                          'Incapacitating anxiety from any intervention aimed at modifying activity',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question8'
                  fill='#8884d8'
                  name='Distress associated with compulsive behavior'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            9. Resistance against compulsions
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q9History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Always try to resist'];
                      case 1:
                        return ['Try to resist most of the time'];
                      case 2:
                        return ['Make some effort to resist'];
                      case 3:
                        return [
                          'Yield to almost all compulsions without attempting to control them, but with some reluctance',
                        ];
                      case 4:
                        return [
                          'Completely and willingly yield to all compulsions',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question9'
                  fill='#8884d8'
                  name='Resistance against compulsions'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            10. Degree of control over compulsive behavior
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q10History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3, 4]} // Manually specify the ticks to be 0, 1, 2, 3
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Complete control'];
                      case 1:
                        return [
                          'Pressure to perform the behavior but usually able to exercise voluntary control over it',
                        ];
                      case 2:
                        return [
                          'Strong pressure to perform behavior, can control it only with difficulty',
                        ];
                      case 3:
                        return [
                          'Very strong drive to perform behavior, must be carried to completion, can only delay with difficulty',
                        ];
                      case 4:
                        return [
                          'Drive to perform behavior experienced as completely involuntary and over-powering, rarely able to even momentarily delay activity.',
                        ];
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question10'
                  fill='#8884d8'
                  name='Degree of control over compulsive behavior'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default YBOCSStatistics;
