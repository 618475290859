import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from './config/firebaseConfig'; // Adjust the path as necessary

/**
 * Copies Firestore document data from a source UID to a destination UID.
 * @param {string} sourceUID - The UID of the source user.
 * @param {string} destinationUID - The UID of the destination user.
 * @param {string} collectionName - The name of the collection containing the document.
 */
const copyFirestoreDocument = async (sourceUID, destinationUID, collectionName) => {
  try {
    // Reference the source document
    const sourceDocRef = doc(db, collectionName, sourceUID);

    // Fetch the source document
    const sourceDoc = await getDoc(sourceDocRef);

    if (!sourceDoc.exists()) {
      console.log(`Source document with UID ${sourceUID} does not exist in collection ${collectionName}`);
      return;
    }

    // Get the data from the source document
    const sourceData = sourceDoc.data();

    // Reference the destination document
    const destinationDocRef = doc(db, collectionName, destinationUID);

    // Write the data to the destination document
    await setDoc(destinationDocRef, sourceData);

    console.log(`Document data copied from ${sourceUID} to ${destinationUID} in collection ${collectionName}`);
  } catch (error) {
    console.error(`Error copying document data from ${sourceUID} to ${destinationUID}:`, error);
    throw new Error('Failed to copy document data.');
  }
};

/**
 * Copies multiple documents from a source UID to a destination UID for several collections.
 * @param {string} sourceUID - The UID of the source user.
 * @param {string} destinationUID - The UID of the destination user.
 * @param {string[]} collections - An array of collection names to copy from.
 */
const copyMultipleDocuments = async (sourceUID, destinationUID, collections) => {
  try {
    const copyTasks = collections.map((collectionName) =>
      copyFirestoreDocument(sourceUID, destinationUID, collectionName)
    );
    await Promise.all(copyTasks);
    console.log('All documents copied successfully!');
  } catch (error) {
    console.error('Error copying multiple documents:', error);
    throw new Error('Failed to copy multiple documents.');
  }
};

export default copyMultipleDocuments;
