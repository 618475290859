import React from 'react';
import './OurVision.css'; // Assuming you extract the styles into a separate CSS file
import ourVision from './ourvision.jpg';
import Navbar from './Navbar';
import Footer from './Footer';

const OurVision = () => {
  return (
    <>
    <Navbar />
    <div className='ourvision-container'>
      <main>
        <section className='vision-section'>
          <div className='image-content10'>
            <img
              src={ourVision}
              alt='Our Vision'
              className='responsive-image'
            />
          </div>
          <br /><br />
          <h2>Our Vision</h2>
          <p>We envision a future where:</p>
          <br />
            <p>
              ✅ Every individual with OCD
              has the tools to regain control of their life.
            </p>
            <br />
            <p>
              ✅ Therapists can seamlessly
              integrate technology into their practice to track progress, offer
              guidance, and support their clients beyond the therapy room.
            </p>
            <br />
            <p>
              ✅ OCD is met with
              understanding, compassion, and resources, not stigma or isolation.
            </p>
          <br /><br />
          <p>
            We’re building a movement that doesn’t just focus on managing OCD
            but helps individuals and their supporters dream bigger, aim higher,
            and thrive.
          </p>
        </section>

        <section className='dream-section'>
          <h2>Our Dream</h2>
          <p>
            <strong>Empower Individuals:</strong> At the heart of OCD Serenity
            is the belief that individuals with OCD can achieve resilience and
            self-mastery. With tools like the SUDS tracker, exposure hierarchy
            builder, and journaling tools, we aim to give users the power to
            take charge of their journey.
          </p>
          <br />
          <p>
            <strong>Support Therapists:</strong> Therapists are the cornerstone
            of OCD treatment. Our dream is to provide them with intuitive,
            impactful tools to track progress, visualize client data, and offer
            personalized guidance that changes lives.
          </p>
          <br />
          <p>
            <strong>Build a Community:</strong> Healing happens in connection.
            OCD Serenity is more than a platform; it’s a community where
            individuals, therapists, and families come together to share
            stories, insights, and hope.
          </p>
        </section>

        <section className='values-section'>
          <h2>Our Values</h2>
          
            <p>
              ✅{' '}
              <strong>Accessibility:</strong> We believe that everyone deserves
              access to tools and resources for managing OCD, regardless of
              their financial circumstances. That’s why our core platform is
              free.
            </p>
            <br />
            <p>
              ✅{' '}
              <strong>Empowerment:</strong> We design our tools to put the user
              in control, helping them track, reflect, and grow with confidence.
            </p>
            <br />
            <p>
              ✅{' '}<strong>Innovation:</strong>{' '}
              Mental health treatment should evolve with technology. We strive
              to create cutting-edge tools that integrate seamlessly into daily
              life and therapy practices.
            </p>
            <br />
            <p>
              ✅{' '}<strong>Compassion:</strong>{' '}
              Everything we do is rooted in empathy for the OCD community,
              therapists, and families who support them.
            </p>
          
        </section>

        <section className='join-section'>
          <h2>Join Our Movement</h2>
          <p>
            OCD Serenity is more than an app—it’s a lifeline, a vision, and a
            shared dream. Together, we can create breakthroughs for individuals,
            equip therapists with impactful tools, and build a future where OCD
            is met with strength and hope.
          </p>
          <br />
          <p>Let’s dream bigger, empower lives, and thrive together.</p>
          
        </section>
      </main>
      
    </div>
    <Footer />
    </>
  );
};

export default OurVision;
