import React, { useState, useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import './OCDTools.css';
import Navbar from './Navbar';
import Warning from './Warning';
import Footer from './Footer';

const OCDTools = () => {
  const [fearTrigger, setFearTrigger] = useState('');
  const [anxietyLevels, setAnxietyLevels] = useState([]);
  const [currentLevel, setCurrentLevel] = useState('');
  const [timeLeft, setTimeLeft] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [intervalId, setIntervalId] = useState(null);
  const [isTriggerSelected, setIsTriggerSelected] = useState(false);
  const [historicalData, setHistoricalData] = useState([]); // To store all sets of anxiety levels for the selected ritual
  const [activeTab, setActiveTab] = useState('Tracker'); // State to track the active tab
  const [timeInterval, setTimeInterval] = useState(5); // New state for time interval

  // Function to handle tab switching
  const handleTabChange = tab => {
    setActiveTab(tab);
  };

  // Function to load settings from localStorage
  const loadSettings = () => {
    const savedSettings = JSON.parse(localStorage.getItem('settings'));
    if (savedSettings && savedSettings.interval) {
      setTimeInterval(savedSettings.interval);
    }
  };

  // Function to load all historical data from localStorage
  const loadAllHistoricalData = () => {
    const storedData = [];

    // Log the content of localStorage for debugging
    console.log('Local Storage Contents:', localStorage);

    // Loop through all items in localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Skip the 'rituals' and 'settings' keys
      if (key === 'rituals' || key === 'settings') {
        continue; // Skip over these keys
      }

      const data = JSON.parse(localStorage.getItem(key));

      // Store only if it's valid array data
      if (data && Array.isArray(data)) {
        storedData.push({ trigger: key, data });
        console.log('Store Data:', storedData);
      }
    }
  };

  const fearTriggers = [
    'Adjusting volume levels to specific numbers',
    'Aligning furniture perfectly',
    'Arranging books in a specific order',
    'Arranging coins or currency notes',
    'Arranging items in a particular order',
    'Arranging pillows in a specific way',
    'Asking for constant reassurance',
    'Avoiding activities at certain times of day',
    'Avoiding certain colors',
    'Avoiding certain foods or drinks',
    'Avoiding certain phrases in conversations',
    'Avoiding certain seats in public places',
    'Avoiding certain shapes',
    'Avoiding certain words while speaking',
    'Avoiding cracks in walls or surfaces',
    'Avoiding cracks on the sidewalk',
    'Avoiding driving over certain areas of road',
    'Avoiding eye contact with certain objects',
    'Avoiding fabrics with specific textures',
    'Avoiding germs in public spaces',
    'Avoiding looking at specific numbers on clocks',
    'Avoiding numbers believed to be unlucky',
    'Avoiding odd or even numbers',
    'Avoiding sharp objects',
    'Avoiding sitting in certain chairs',
    'Avoiding specific numbers',
    'Avoiding specific people or locations',
    'Avoiding specific TV channels or shows',
    'Avoiding stepping on shadows',
    'Avoiding stepping on specific floor tiles',
    'Avoiding touching certain materials',
    'Avoiding using certain types of soap',
    'Checking appliances multiple times',
    'Checking bank statements repeatedly',
    'Checking belongings for damage',
    'Checking car tires repeatedly',
    'Checking clothes for dirt or stains',
    'Checking doors multiple times',
    'Checking drawers to ensure they are closed',
    'Checking email drafts multiple times',
    'Checking expiration dates obsessively',
    'Checking for bad luck symbols',
    'Checking for signs of illness excessively',
    'Checking pockets repeatedly for keys or phone',
    'Checking seatbelt lock multiple times',
    'Checking stove knobs multiple times',
    'Checking the alignment of frames on walls',
    'Checking the mailbox excessively',
    'Checking the placement of personal belongings',
    'Checking wallet or purse contents',
    'Cleaning glasses or lenses repeatedly',
    'Cleaning shoes before entering home',
    'Cleaning smartphone case obsessively',
    'Clearing browser history repeatedly',
    'Color-coding clothing or accessories',
    'Collecting unnecessary items',
    'Correcting others’ actions to fit personal standards',
    'Correcting others’ language mistakes',
    'Counting bites of food while eating',
    'Counting body movements',
    'Counting fingers on hands',
    'Counting letters in words mentally',
    'Counting objects or steps',
    'Counting steps on stairs mentally',
    'Counting steps while walking',
    'Counting while performing tasks',
    'Covering sharp corners with cushions',
    'Double-knotting shoelaces repeatedly',
    'Ensuring chairs are aligned with tables',
    'Ensuring curtains are perfectly straight',
    'Ensuring doors are closed in a specific way',
    'Ensuring symmetrical placement of objects',
    'Ensuring windows are perfectly aligned',
    'Erasing written text to avoid errors',
    'Flicking switches in specific patterns',
    'Fixating on exact times for activities',
    'Holding breath in certain situations',
    'Holding objects until they "feel right"',
    'Locking and unlocking car doors repeatedly',
    'Locking and unlocking doors',
    'Measuring distances between objects',
    'Mentally repeating words or phrases',
    'Moving objects back and forth',
    'Obsessively wiping dust off surfaces',
    'Organizing books alphabetically',
    'Organizing clothing by color',
    'Organizing desk or workspace meticulously',
    'Organizing items symmetrically',
    'Picturing specific images in the mind',
    'Placing grocery items in a fixed pattern',
    'Placing items on shelves in a particular sequence',
    'Placing shoes in specific positions',
    'Placing toilet paper in a particular way',
    'Pre-checking locks before leaving the house',
    'Pushing objects to align with edges',
    'Putting on clothes in a specific order',
    'Reading labels on products multiple times',
    'Reading signs or labels multiple times',
    'Re-assessing decisions for long periods',
    'Re-checking alarm clock settings',
    'Re-arranging items on shelves repeatedly',
    'Re-reading text for errors',
    'Removing perceived contaminants',
    'Repeating daily routines in the same order',
    'Repeating driving routes unnecessarily',
    'Repeating hand gestures for balance',
    'Repeating physical actions (e.g., opening and closing a door)',
    'Repeating prayers until they feel right',
    'Repeating sentences until they "feel right"',
    'Repeating specific prayers or chants',
    'Repeating steps when walking',
    'Rewriting sentences for perfection',
    'Separating items by color or type',
    'Storing items in specific ways',
    'Switching lights on and off a set number of times',
    'Tapping objects repeatedly',
    'Touching body parts in specific ways',
    'Touching objects a specific number of times',
    'Touching objects to undo "bad" thoughts',
    'Touching surfaces in specific sequences',
    'Touching walls while walking',
    'Turning knobs in specific sequences',
    'Turning lights on and off repeatedly',
    'Turning TV channels in specific orders',
    'Unplugging electrical devices unnecessarily',
    'Washing clothes unnecessarily',
    'Washing dishes multiple times',
    'Washing feet multiple times',
    'Washing hands repeatedly',
    'Wearing clothes in a specific order',
    'Wearing certain colors on specific days',
    'Wearing specific jewelry for protection',
    'Wiping bathroom surfaces constantly',
    'Wiping doorknobs with tissues',
    'Wiping electronic devices constantly',
    'Wiping hands excessively with sanitizers',
    'Wiping phone screen repeatedly',
  ];

  const storedRituals =
    JSON.parse(localStorage.getItem('rituals')) || fearTriggers;

  const [rituals] = useState(storedRituals);

  const startRecording = () => {
    if (fearTrigger) {
      setIsRecording(true);
      setIsTriggerSelected(true);
      setTimeLeft(0);
      setAnxietyLevels([]);
    } else {
      alert('Please select a ritual before starting.');
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    clearInterval(intervalId);
    saveData(); // Save the data before resetting
    resetTool();
    alert(`Your anxiety levels have been saved.`);
    handleTabChange('Dashboard');
  };

  const cancelRecording = () => {
    setIsRecording(false);
    clearInterval(intervalId);
    resetTool();
  };

  const resetTool = () => {
    setTimeLeft(0);
    setAnxietyLevels([]);
    setCurrentLevel('');
    setIsTriggerSelected(false);
  };

  const recordAnxietyLevel = () => {
    if (currentLevel !== '') {
      const newLevels = [
        ...anxietyLevels,
        {
          time: anxietyLevels.length * timeInterval,
          level: parseInt(currentLevel, 10),
        },
      ];
      setAnxietyLevels(newLevels);
      setCurrentLevel('');

      const id = setInterval(() => {
        setTimeLeft(prev => {
          if (prev <= 0) {
            clearInterval(id);
            return 0;
          }
          return prev - 1;
        });
      }, 1000);

      setTimeLeft(timeInterval * 60);
      if (intervalId) clearInterval(intervalId);
      setIntervalId(id);
    }
  };

  const saveData = () => {
    if (anxietyLevels.length > 0) {
      const storedData = JSON.parse(localStorage.getItem(fearTrigger)) || [];
      const updatedData = [...storedData, anxietyLevels];
      localStorage.setItem(fearTrigger, JSON.stringify(updatedData));
    }
  };

  const loadHistoricalData = trigger => {
    const data = JSON.parse(localStorage.getItem(trigger)) || [];
    setHistoricalData(data);
  };

  const clearHistory = () => {
    if (fearTrigger) {
      const isConfirmed = window.confirm(
        `Are you sure you want to clear the history for the ritual "${fearTrigger}"?`
      );
      if (isConfirmed) {
        localStorage.removeItem(fearTrigger);
        setHistoricalData([]); // Clear the historical data from state
        alert(`History for "${fearTrigger}" has been cleared.`);
      }
    }
  };

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    localStorage.setItem('rituals', JSON.stringify(rituals));
  }, [rituals]);

  // Function to format time from seconds to "minutes:seconds"
  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60); // Calculate the number of minutes
    const remainingSeconds = seconds % 60; // Calculate the remaining seconds
    // Ensure seconds are always displayed as two digits (e.g., 05 instead of 5)
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    const formattedminutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedminutes}:${formattedSeconds}`; // Return the formatted time as "minutes:seconds"
  };

  useEffect(() => {
    loadSettings(); // Load settings on component mount
  }, []);

  useEffect(() => {
    if (timeLeft === 0 && intervalId) {
      clearInterval(intervalId);
    }
  }, [timeLeft, intervalId]);

  useEffect(() => {
    if (fearTrigger) {
      loadHistoricalData(fearTrigger);
    }
  }, [fearTrigger]);

  // Load all historical data when the Dashboard tab is clicked
  useEffect(() => {
    if (activeTab === 'Dashboard') {
      loadAllHistoricalData();
    }
  }, [activeTab]);

  // Save rituals to localStorage whenever the list changes
  useEffect(() => {
    localStorage.setItem('rituals', JSON.stringify(rituals));
  }, [rituals]);

  return (
    <>
      <Navbar />
      <div className='suds-tool'>
        <Warning />
        <h1>SUDS Tracker</h1>
        <div className='tracker'>
          
          <p>
            <strong>Start by thinking about your anxiety level.</strong> How
            anxious or uncomfortable are you feeling right now? If you are
            performing a ritual or an exposure task, try to rate how much
            distress it causes.
            <br />
            <br />
            <strong>Rate your distress on a scale from 0 to 100.</strong>
            <ul>
              - <strong>00</strong>: 😊 no distress at all.
              <br />- <strong>10</strong>: 😌 alert and awake, concentrating
              well.
              <br />- <strong>20</strong>: 🙂 minimal distress.
              <br />- <strong>30</strong>: 😐 mild distress, no interference
              with performance.
              <br />- <strong>50</strong>: 😕 uncomfortable but can continue to
              perform.
              <br />- <strong>70</strong>: 😟 quite distressed, interfering with
              performance.
              <br />- <strong>80</strong>: 😣 very distressed, can’t
              concentrate.
              <br />- <strong>90</strong>: 😫 extremely distressed.
              <br />- <strong>100</strong> 😱 the most distress or panic you
              can imagine.
              <br />
            </ul>
            <strong>
              Track your SUDS scores regularly with our tracker here below.
            </strong>
          </p>

          <div className='controls'>
            <div className='timer'>
              <p>
                <span className='time-left'>{formatTime(timeLeft)}</span>
              </p>
            </div>
            {!isTriggerSelected ? (
              <div className='controls'>
                <label>
                  <select
                    value={fearTrigger}
                    onChange={e => setFearTrigger(e.target.value)}
                  >
                    <option value='' disabled>
                      Select a ritual to start your exposure exercise
                    </option>
                    {storedRituals.map((trigger, index) => (
                      <option key={index} value={trigger}>
                        {trigger}
                      </option>
                    ))}
                  </select>
                  <br />
                  
                </label>

                {/* Button group for "Start Recording" and "Clear History" */}

                {!isRecording && fearTrigger !== '' && (
                  <>
                    <div className='exposure-explanation'>
                      <p>
                        📌 Click on <strong>Start Exposure</strong> and save
                        every {timeInterval} minutes your level of anxiety.
                      </p>
                    </div>
                    <div className='button-start-clear'>
                      <button onClick={startRecording} className='start-button'>
                        Start Exposure
                      </button>

                      <button onClick={clearHistory} className='stop-button'>
                        Clear This Rituals' History
                      </button>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <></>
            )}

            {isRecording ? (
              <>
                {timeLeft > 0 && (
                  <>
                    <button onClick={cancelRecording} className='stop-button'>
                      Discard
                    </button>
                    <br />
                  </>
                )}

                {timeLeft === 0 && (
                  <>
                    <div className='exposure-explanation'>
                      <p>
                        📌 Enter your level of anxiety and click on{' '}
                        <strong>Save Anxiety Level</strong> to continue your
                        exposure.
                        <br />
                        📌 Select <strong>Stop Exposure</strong> to finish the
                        exposure.
                        <br />
                        📌 Select <strong>Discard</strong> to stop your exposure
                        exercice without saving your levels of anxiety.
                      </p>
                    </div>
                    <div className='input-group'>
                      <div className='input-row'>
                        <label>
                          <input
                            type='number'
                            value={currentLevel}
                            onChange={e => setCurrentLevel(e.target.value)}
                            min='0'
                            max='100'
                          />
                        </label>

                        {/* "Record Anxiety Level" button on the same line as input */}
                        <button
                          onClick={recordAnxietyLevel}
                          className='start-button'
                        >
                          Save Anxiety Level
                        </button>
                      </div>

                      {/* "Stop Recording" and "Cancel" buttons below the input and "Record Anxiety Level" button */}
                      <div className='button-group'>
                        <button onClick={stopRecording} className='stop-button'>
                          Stop Exposure
                        </button>
                        <button
                          onClick={cancelRecording}
                          className='stop-button'
                        >
                          Discard
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </>
            ) : (
              <></>
            )}
          </div>

          <div className='anxiety-chart'>
            <h2>
              Recorded Anxiety Levels For{' '}
              <span className='fear-color'>{fearTrigger}</span>
            </h2>
            <ResponsiveContainer width='100%' height={300}>
              <LineChart>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  type='number'
                  dataKey='time'
                  label={{
                    value: 'Time (min)',
                    position: 'insideBottomRight',
                    offset: -5,
                  }}
                />
                <YAxis
                  domain={[0, 100]}
                  label={{
                    value: 'Anxiety Level',
                    angle: -90,
                    position: 'insideLeft',
                  }}
                />
                <Tooltip />
                <Legend />
                {historicalData.map((dataset, index) => (
                  <Line
                    key={index}
                    type='monotone'
                    data={dataset}
                    dataKey='level'
                    stroke={`hsl(${(index * 60) % 360}, 100%, 50%)`}
                    name={index + 1} // Add this line to label each record
                  />
                ))}
                {anxietyLevels.length > 0 && (
                  <Line
                    type='monotone'
                    data={anxietyLevels}
                    dataKey='level'
                    stroke='#007bff'
                  />
                )}
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default OCDTools;
