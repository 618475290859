import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useAudio } from './AudioContext';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig';
import { signOut } from 'firebase/auth';
import MessageModalClient from './MessageModalClient';
import { onAuthStateChanged } from 'firebase/auth';

import './Navbar.css';

function NavbarClient() {
  const { showAudioPlayer, handleMusicClick, stopMusic } = useAudio();

  const useUnreadMessages = clientId => {
    const [unreadCount, setUnreadCount] = useState(0);

    useEffect(() => {
      if (!clientId) return;

      const messagesQuery = query(
        collection(db, 'messages'),
        where('receiverId', '==', clientId),
        where('read', '==', false)
      );

      const unsubscribeMessages = onSnapshot(messagesQuery, snapshot => {
        setUnreadCount(snapshot.size);
      });

      return () => {
        unsubscribeMessages();
      };
    }, [clientId]);

    return unreadCount;
  };

  const [userId, setUserId] = useState(null);
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [isMessagesOpen, setIsMessagesOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleOpenMessages = () => {
    setIsMessagesOpen(true);
  };

  const handleCloseMessages = () => {
    setIsMessagesOpen(false);
  };

  const unreadCount = useUnreadMessages(userId);

  useEffect(() => {
    if (unreadCount > 0) {
      setIsMessagesOpen(true);
    }
  }, [unreadCount]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (user) {
        setUserId(user.uid);
      } else {
        setUserId(null);
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  return (
    <nav className='navbar'>
      <div className='logo'>OCD Serenity</div>
      <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className='line1'></div>
        <div className='line2'></div>
        <div className='line3'></div>
      </div>
      <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
        <ul className='nav-links'>
          <li>
            <Link to='/ocdnews'>📰 OCD News Feed</Link>
          </li>
          <li>
            <Link to='/agenda'>📅 OCD Agenda</Link>
          </li>
          <li className='menu-item'>
            <span>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/yale-brown'>📝 Yale-Brown OCD Assessment</Link>
              </li>
              <li>
                <Link to='/gad7'>📝 GAD-7 Anxiety Assessment</Link>
              </li>
              <li>
                <Link to='/phq9'>📝 PHQ-9 Depression Assessment</Link>
              </li>
              <li>
                <Link to='/client-trigger-tracker'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/client-mood-tracker'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/client-exposure-tracker'>
                  🚀 Exposure Hierarchy Builder
                </Link>
              </li>
              <li>
                <Link to='/client-suds-tracker'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/coupon'>🎟️ Reassurance Coupon</Link>
              </li>
              <li>
                <button
                  onClick={handleMusicClick}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                  onMouseEnter={e => (e.target.style.backgroundColor = 'black')}
                  onMouseLeave={e =>
                    (e.target.style.backgroundColor = 'transparent')
                  }
                >
                  🎵 Relaxing Sound
                </button>
              </li>
              <li>
                <Link to='/journal'>✍️ Journaling</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/client-dashboard'>📊 Dashboard</Link>
          </li>
          <li onClick={handleLogout}>
            <span>👤 Logout</span>
          </li>
        </ul>
        {showAudioPlayer && (
          <button
            onClick={stopMusic}
            style={{ position: 'absolute', top: '100px', right: '10px' }}
          >
            Stop Music
          </button>
        )}
        {isMessagesOpen && userId && (
          <MessageModalClient clientId={userId} onClose={handleCloseMessages} />
        )}
      </div>
    </nav>
  );
}

export default NavbarClient;
