import React, { useState } from 'react';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from './config/firebaseConfig'; // Adjust path to your Firebase config

const TherapistFeedback = ({ therapistId }) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert('Please provide your feedback before submitting.');
      return;
    }

    setLoading(true);

    try {
      const feedbackDocRef = doc(db, 'therapistFeedback', therapistId); // Unique feedback doc per therapist
      await setDoc(feedbackDocRef, {
        therapistId,
        feedback,
        createdAt: serverTimestamp(),
      }, { merge: true }); // Allows updating if feedback already exists

      setIsSubmitted(true);
      //alert('Thank you for your feedback! We will review it shortly.');
      setFeedback(''); // Clear the input
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (isSubmitted) {
    return (
      <div className="therapist-dashboard">
        <p>Thank you for your feedback! We will review it shortly. We value your input.</p>
      </div>
    );
  }

  return (
    <div className='therapist-dashboard'>
      <h2 className='dashboard-title'>Share Your Suggestions</h2>
      <p>
        Is there a tool, feature, or method you’d like us to implement ? 
        Let us know your ideas so we can make the app better for you and your clients!
      </p>
      <textarea
        value={feedback}
        onChange={(e) => setFeedback(e.target.value)}
        placeholder="Type your suggestion here..."
        rows="5"
        style={{
          width: '90%',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          marginBottom: '10px',
          backgroundColor: '#575656',
          color: 'white',
        }}
      ></textarea>
      <button
        onClick={handleFeedbackSubmit}
        disabled={loading}
        style={{
          backgroundColor: loading ? '#ccc' : '#007bff',
          color: '#fff',
          border: 'none',
          padding: '10px 15px',
          borderRadius: '5px',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
      >
        {loading ? 'Submitting...' : 'Submit Feedback'}
      </button>
    </div>
  );
};

export default TherapistFeedback;
