import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, orderBy, where } from 'firebase/firestore';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';
import { db } from './config/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './config/firebaseConfig'; // Add this import
import NavbarClient from './NavbarClient';

const LogsDisplay = () => {
  const [logs, setLogs] = useState([]);
  const [therapistCount, setTherapistCount] = useState(0);
  const [therapistPaidCount, setTherapistPaidCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [individualCount, setIndividualCount] = useState(0);
  const [revenue, setRevenue] = useState(0);

  const [therapistData, setTherapistData] = useState([]);

  // Function to fetch logs from Firestore
  const fetchLogs = async () => {
    try {
      const logsRef = collection(db, 'Logs');
      const q = query(logsRef, orderBy('timestamp', 'desc'));
      const querySnapshot = await getDocs(q);
      const logsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setLogs(logsData);
    } catch (error) {
      console.error('Error fetching logs:', error);
    }
  };

  // Function to compute the number of account with role is therapist from db users in Firestore
  const fetchTherapistCount = async () => {
    try {
      const usersRef = collection(db, 'users');
      const q = query(usersRef);
      const querySnapshot = await getDocs(q);
      const usersData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      const therapistCount = usersData.filter(
        user => user.role === 'therapist'
      ).length;
      setTherapistCount(therapistCount);
      const therapistPaidCount = usersData.filter(
        user => user.role === 'therapist' && user.status === 'paid'
      ).length;
      setTherapistPaidCount(therapistPaidCount);
      const clientCount = usersData.filter(
        user => user.role === 'client'
      ).length;
      setClientCount(clientCount);
      const individualCount = usersData.filter(
        user => user.role === 'individual'
      ).length;
      setIndividualCount(individualCount);
      const revenue = therapistPaidCount * 29.99;
      setRevenue(revenue);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  // *******************************************************************************************
  // ******************************* CHECK IF USER LOGGED IN ***********************************
  // *******************************************************************************************
  const navigate = useNavigate();
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      if (!user) {
        navigate('/login');
      } else {
        // Initial fetch on component mount
        fetchLogs();
        fetchTherapistCount();

        // Set interval to refresh logs every 15 minutes
        const intervalId = setInterval(fetchLogs, 900000); // 900000 ms = 15 minutes

        // Clean up interval on component unmount
        return () => clearInterval(intervalId);
      }
    });
    return () => unsubscribe();
  }, []);

  // Function to get row color based on eventType
  const getRowColor = note => {
    switch (note) {
      case 'Failed to register therapist':
      case 'Failed to register client or individual':
        return 'red';
      case 'Therapist successfully registered !':
      case 'Individu registration complete!':
      case 'Client successfully registered and linked to therapist.':
        return 'green';
      case 'Logged in successfully!':
        return 'black';
      default:
        return 'white';
    }
  };

  return (
    <>
      <NavbarClient />
      <div className='dashboard' style={{ marginBottom: '10px' }}>
        <p>Therapist count:</p>
        <p>{therapistCount}</p>
        <br />
        <p>Therapist paid count:</p>
        <p>{therapistPaidCount}</p>
        <br />
        <p>Client count:</p>
        <p>{clientCount}</p>
        <br />
        <p>Individual count:</p>
        <p>{individualCount}</p>
        <br />
        <p>Revenue:</p>
        <p>{revenue}$/month</p>
      </div>
      
      <div className='dashboard'>
        {logs.length > 0 ? (
          <table style={{ borderCollapse: 'collapse', width: '1200px' }}>
            <tbody>
              {logs.map(log => (
                <tr
                  key={log.id}
                  style={{ backgroundColor: getRowColor(log.note) }}
                >
                  <td style={{ padding: '10px', border: '0px solid #ddd' }}>
                    {log.timestamp?.toDate().toLocaleString()}
                  </td>
                  <td style={{ padding: '10px', border: '0px solid #ddd' }}>
                    {log.email}
                  </td>
                  <td style={{ padding: '10px', border: '0px solid #ddd' }}>
                    {log.role}
                  </td>
                  <td style={{ padding: '10px', border: '0px solid #ddd' }}>
                    {log.eventType}
                  </td>
                  <td style={{ padding: '10px', border: '0px solid #ddd' }}>
                    {log.note}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <p>No logs available</p>
        )}
      </div>
    </>
  );
};

export default LogsDisplay;
