import React, { useState, useEffect } from 'react';
import {
  doc,
  setDoc,
  getDocs,
  getDoc,
  serverTimestamp,
  updateDoc,
  increment,
  collection,
} from 'firebase/firestore';
import { db } from './config/firebaseConfig'; // Adjust path to your Firebase config

const TherapistFeedback = ({ therapistId }) => {
  const [feedback, setFeedback] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [features, setFeatures] = useState([]);

  // Fetch features on component mount
  useEffect(() => {
    const fetchFeatures = async () => {
      const featuresCol = collection(db, 'featureVotes');
      const featureSnapshot = await getDocs(featuresCol);
      const featuresList = featureSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeatures(featuresList);
    };

    fetchFeatures();
  }, []);

  const handleFeedbackSubmit = async () => {
    if (!feedback.trim()) {
      alert('Please provide your feedback before submitting.');
      return;
    }

    setLoading(true);

    try {
      const feedbackDocRef = doc(db, 'therapistFeedback', therapistId);
      await setDoc(
        feedbackDocRef,
        {
          therapistId,
          feedback,
          createdAt: serverTimestamp(),
        },
        { merge: true }
      );

      // setIsSubmitted(true);
      alert('Thank you for your feedback! We will review it shortly. We value your input.');
      setFeedback(''); // Clear the input
    } catch (error) {
      console.error('Error submitting feedback:', error);
      alert('Failed to submit feedback. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleVote = async featureId => {
    const featureDoc = doc(db, 'featureVotes', featureId);
    const featureData = await getDoc(featureDoc);
    if (featureData.exists()) {
      const currentVote = featureData.data().voters?.[therapistId];

      if (currentVote) {
        alert('You have already voted for this feature!');
        return;
      }

      await updateDoc(featureDoc, {
        upvotes: increment(1),
        [`voters.${therapistId}`]: 'upvote',
      });

      const featureSnapshot = await getDocs(collection(db, 'featureVotes'));
      const updatedFeatures = featureSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
      }));
      setFeatures(updatedFeatures);
      alert('Thank you for your vote!');
    }
  };

  if (isSubmitted) {
    return (
      <div className='therapist-dashboard'>
        <p>
          Thank you for your feedback! We will review it shortly. We value your
          input.
        </p>
      </div>
    );
  }

  return (
    <div className='therapist-dashboard'>
      <div className='feature-container'>
        <h2 className='dashboard-title'>Incoming Features & Improvements</h2>
        <p>
          You can vote on or suggest new features, helping us prioritize the
          tools that will make this platform as useful and effective as possible
          for you.
        </p>
        <div className='feature1-row'>
          <div className='feature-cell'>
            <strong>Feature</strong>
          </div>
          <div className='feature-cell'>
            <b>Description</b>
          </div>
          <div className='feature-cell'>
            <b>Votes</b>
          </div>
          <div className='feature-cell'>
            <b>Action</b>
          </div>
        </div>
        {features.map(feature => (
          <div key={feature.id} className='feature-table'>
            <div className='feature-row'>
              <div className='feature-cell'>
                <strong>{feature.title}</strong>
              </div>
              <div className='feature-cell'>{feature.description}</div>
              <div className='feature-cell'>👍 {feature.upvotes}</div>
              <div className='feature-cell'>
                <button
                  style={{
                    backgroundColor: '#4CAF50',
                    color: 'white',
                    border: 'none',
                    padding: '10px 20px',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    transition: 'background-color 0.3s ease',
                  }}
                  onMouseOver={e =>
                    (e.target.style.backgroundColor = '#45a049')
                  }
                  onMouseOut={e => (e.target.style.backgroundColor = '#4CAF50')}
                  onClick={() => handleVote(feature.id)}
                >
                  {feature.voters?.[therapistId] ? '✅' : 'Vote'}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <br />
      <h2 className='dashboard-title'>Share Your Suggestions</h2>
      <p>
        Is there a tool, feature, or method you’d like us to implement ? Let us
        know your ideas so we can make the app better for you and your patients.
      </p>
      <textarea
        value={feedback}
        onChange={e => setFeedback(e.target.value)}
        placeholder='Type your suggestion here...'
        rows='5'
        style={{
          width: '90%',
          padding: '10px',
          borderRadius: '5px',
          border: '1px solid #ccc',
          marginBottom: '10px',
          backgroundColor: '#575656',
          color: 'white',
        }}
      ></textarea>
      <button
        onClick={handleFeedbackSubmit}
        disabled={loading}
        style={{
          backgroundColor: loading ? '#ccc' : '#007bff',
          color: '#fff',
          border: 'none',
          padding: '10px 15px',
          borderRadius: '5px',
          cursor: loading ? 'not-allowed' : 'pointer',
        }}
      >
        {loading ? 'Submitting...' : 'Submit Feedback'}
      </button>
    </div>
  );
};

export default TherapistFeedback;
