import React, { useEffect, useState } from 'react';
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { onAuthStateChanged } from 'firebase/auth';
import {
  LineChart,
  BarChart,
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'; // Import Recharts
import './YaleBrownForm.css';
import { useLocation } from 'react-router-dom';

import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import Footer from './Footer';

const GAD7Statistics = () => {
  const location = useLocation();
  const clientId = location.state?.clientId; // Get client.id from state

  const [scoreHistory, setScoreHistory] = useState([]); // Store the historical scores
  const [Q1History, setQ1History] = useState([]);
  const [Q2History, setQ2History] = useState([]);
  const [Q3History, setQ3History] = useState([]);
  const [Q4History, setQ4History] = useState([]);
  const [Q5History, setQ5History] = useState([]);
  const [Q6History, setQ6History] = useState([]);
  const [Q7History, setQ7History] = useState([]);
  const [Q8History, setQ8History] = useState([]);

  const currentUser = auth.currentUser; // Get the logged-in user
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          //setUserRole(userDoc.data().role);
          //console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  // Fetch the most recent form for the user when loading the page
  useEffect(() => {
    const fetchForms = async () => {
      if (currentUser) {
        const userDocRef = doc(db, 'GAD7_assessment', clientId); // Reference to the user document
        const formsCollectionRef = collection(userDocRef, 'GAD7_forms'); // Sub-collection for the forms

        try {
          // Query the sub-collection to get all forms and order by timestamp
          const q = query(formsCollectionRef, orderBy('timestamp', 'asc')); // Use 'asc' to get the oldest forms first
          const querySnapshot = await getDocs(q);
          //console.log('lst form: ',querySnapshot);

          if (!querySnapshot.empty) {
            const formsData = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsData.push({
                timestamp: data.timestamp.toDate(),
                totalScore: data.scores.totalScore,
              });
            });
            setScoreHistory(formsData); // Store the historical scores

            const formsQ1 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ1.push({
                timestamp: data.timestamp.toDate(),
                question1: data.answers.question1,
              });
            });
            setQ1History(formsQ1); // Store the historical scores

            const formsQ2 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ2.push({
                timestamp: data.timestamp.toDate(),
                question2: data.answers.question2,
              });
            });
            setQ2History(formsQ2); // Store the historical scores

            const formsQ3 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ3.push({
                timestamp: data.timestamp.toDate(),
                question3: data.answers.question3,
              });
            });
            setQ3History(formsQ3); // Store the historical scores

            const formsQ4 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ4.push({
                timestamp: data.timestamp.toDate(),
                question4: data.answers.question4,
              });
            });
            setQ4History(formsQ4); // Store the historical scores

            const formsQ5 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ5.push({
                timestamp: data.timestamp.toDate(),
                question5: data.answers.question5,
              });
            });
            setQ5History(formsQ5); // Store the historical scores

            const formsQ6 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ6.push({
                timestamp: data.timestamp.toDate(),
                question6: data.answers.question6,
              });
            });
            setQ6History(formsQ6); // Store the historical scores

            const formsQ7 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ7.push({
                timestamp: data.timestamp.toDate(),
                question7: data.answers.question7,
              });
            });
            setQ7History(formsQ7); // Store the historical scores

            const formsQ8 = [];
            querySnapshot.forEach(doc => {
              const data = doc.data();
              formsQ8.push({
                timestamp: data.timestamp.toDate(),
                question8: data.answers.question8,
              });
            });
            setQ8History(formsQ8); // Store the historical scores
          }
        } catch (error) {
          console.error('Error fetching forms:', error);
        }
      }
      setLoading(false); // Set loading to false once data is fetched
    };

    fetchForms(); // Fetch the most recent form and score
  }, [currentUser]);

  const [successMessage, setSuccessMessage] = useState('');

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while waiting for data
  }
  return (
    <>
      <NavbarTherapist />
      <div className='yale-brown-form'>
        <h1>GAD-7 (General Anxiety Disorder-7) History</h1>
        <br />

        <div className='assessment-intro'>
          <h2>Historical GAD-7 Scores</h2>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={scoreHistory}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 4, 9, 14, 21]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 4:
                        return 'Minimal (0–4)';
                      case 9:
                        return 'Mild (5–9)';
                      case 14:
                        return 'Moderate (10–14)';
                      case 21:
                        return 'Severe (15–21)';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    switch (true) {
                      case value <= 4:
                        return ['Minimal Anxiety', value];
                      case value <= 9:
                        return ['Mild Anxiety', value];
                      case value <= 14:
                        return ['Moderate Anxiety', value];
                      case value <= 21:
                        return ['Severe Anxiety', value];
                      default:
                        return [value, value];
                    }
                  }}
                />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='totalScore'
                  stroke='#8884d8'
                  name='GAD-7 Scores'
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            1. Feeling nervous, anxious, or on edge
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q1History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question1'
                  fill='#8884d8'
                  name='Feeling nervous, anxious, or on edges'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            2. Not being able to stop or control worrying
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q2History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question2'
                  fill='#8884d8'
                  name='Not being able to stop or control worrying'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            3. Worrying too much about different things
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q3History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question3'
                  fill='#8884d8'
                  name='Worrying too much about different things'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>4. Trouble relaxing</h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q4History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question4'
                  fill='#8884d8'
                  name='Trouble relaxing'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            5. Being so restless that it's hard to sit still
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q5History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question5'
                  fill='#8884d8'
                  name='Being so restless that it is hard to sit still'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            6. Becoming easily annoyed or irritable
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q6History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question6'
                  fill='#8884d8'
                  name='Becoming easily annoyed or irritable'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            7. Feeling afraid as if something awful might happen
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q7History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not at all';
                      case 1:
                        return 'Several days';
                      case 2:
                        return 'More than half the days';
                      case 3:
                        return 'Nearly every day';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not at all'];
                      case 1:
                        return [
                          'Several days',
                        ];
                      case 2:
                        return [
                          'More than half the days',
                        ];
                      case 3:
                        return [
                          'Nearly every day',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question7'
                  fill='#8884d8'
                  name='Feeling afraid as if something awful might happen'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>

        <br />
        <div className='assessment-intro'>
          <h3 style={{ color: '#f09a73' }}>
            If you checked any problems, how difficult have they made it for you
            to do your work, take care of things at home, or get along with
            other people?
          </h3>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <BarChart data={Q8History}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <YAxis
                  ticks={[0, 1, 2, 3]} // Manually specify the ticks to be 0, 1, 2, 3
                  tickFormatter={value => {
                    switch (value) {
                      case 0:
                        return 'Not difficult at all';
                      case 1:
                        return 'Somewhat difficult';
                      case 2:
                        return 'Very difficult';
                      case 3:
                        return 'Extremely difficult';
                      default:
                        return value;
                    }
                  }}
                  tick={{ fontSize: 10, fill: 'white' }}
                />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                  formatter={value => {
                    // Customize the tooltip value based on the value
                    switch (value) {
                      case 0:
                        return ['Not difficult at all'];
                      case 1:
                        return [
                          'Somewhat difficult',
                        ];
                      case 2:
                        return [
                          'Very difficult',
                        ];
                      case 3:
                        return [
                          'Extremely difficult',
                        ];
                      
                      default:
                        return [value];
                    }
                  }}
                />
                <Legend />
                <Bar
                  dataKey='question8'
                  fill='#8884d8'
                  name='If you checked any problems, how difficult have they made it for you
          to do your work, take care of things at home, or get along with other
          people?'
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default GAD7Statistics;
