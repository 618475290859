import React, { useEffect, useState } from 'react';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { onAuthStateChanged } from 'firebase/auth';
import './YaleBrownForm.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';

const YaleBrownForm = () => {
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [scores, setScores] = useState({
    obsessions: 0,
    compulsions: 0,
    total: 0,
  });

  const [answers, setAnswers] = useState({
    harmSelf: { past: false, current: false },
    harmOthers: { past: false, current: false },
    violentImages: { past: false, current: false },
    blurtObscenities: { past: false, current: false },
    embarrassing: { past: false, current: false },
    unwantedImpulse: { past: false, current: false },
    stealing: { past: false, current: false },
    harmByCarelessness: { past: false, current: false },
    responsibility: { past: false, current: false },
    bodilyWaste: { past: false, current: false },
    dirtGerms: { past: false, current: false },
    environmentalContaminants: { past: false, current: false },
    householdCleansers: { past: false, current: false },
    animals: { past: false, current: false },
    stickySubstances: { past: false, current: false },
    illnessContamination: { past: false, current: false },
    contaminateOthers: { past: false, current: false },
    sexualThoughts: { past: false, current: false },
    sexualChildrenIncest: { past: false, current: false },
    homosexuality: { past: false, current: false },
    aggressiveSexualBehavior: { past: false, current: false },
    hoarding: { past: false, current: false },
    sacrilege: { past: false, current: false },
    morality: { past: false, current: false },
    symmetry: { past: false, current: false },
    rememberThings: { past: false, current: false },
    fearSayingThings: { past: false, current: false },
    fearNotSayingRight: { past: false, current: false },
    fearLosingThings: { past: false, current: false },
    intrusiveImages: { past: false, current: false },
    intrusiveSounds: { past: false, current: false },
    noises: { past: false, current: false },
    luckyUnluckyNumbers: { past: false, current: false },
    colorsSignificance: { past: false, current: false },
    superstitiousFears: { past: false, current: false },
    illnessConcern: { past: false, current: false },
    appearanceConcern: { past: false, current: false },
    handWashing: { past: false, current: false },
    showeringGrooming: { past: false, current: false },
    cleaningItems: { past: false, current: false },
    avoidingContaminants: { past: false, current: false },
    checkingHarm: { past: false, current: false },
    checkingHarm43: { past: false, current: false },
    checkingTerrible: { past: false, current: false },
    checkingMistakes: { past: false, current: false },
    physicalCondition: { past: false, current: false },
    repeatingActivities: { past: false, current: false },
    repeatingActivities47: { past: false, current: false },
    countingCompulsions: { past: false, current: false },
    orderingArranging: { past: false, current: false },
    hoardingCollecting: { past: false, current: false },
    mentalRituals: { past: false, current: false },
    confessing: { past: false, current: false },
    touching: { past: false, current: false },
    preventingHarm: { past: false, current: false },
    ritualizedEating: { past: false, current: false },
    superstitiousBehaviors: { past: false, current: false },
    hairPulling: { past: false, current: false },
    obsessiveThoughtsTime: 0,
    obsessiveThoughtsInterference: 0,
    obsessiveThoughtsDistress: 0,
    obsessiveThoughtsResistance: 0,
    obsessiveThoughtsControl: 0,
    compulsiveBehaviorsTime: 0,
    compulsiveBehaviorsInterference: 0,
    compulsiveBehaviorsDistress: 0,
    compulsiveBehaviorsResistance: 0,
    compulsiveBehaviorsControl: 0,
  });

  useEffect(() => {
    // Trigger calculateScores when answers state is updated
    calculateScores();
  }, [answers]);

  useEffect(() => {
    // Check user's authentication status
    const unsubscribe = onAuthStateChanged(auth, async user => {
      if (user) {
        // User is logged in
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
        }
        const userDocRef = doc(getFirestore(), 'YBOCS_assessment', user.uid);
        try {
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            // Set the fetched data to state
            setAnswers(data.answers || {});
            setScores(
              data.scores || { obsessions: 0, compulsions: 0, total: 0 }
            );
          }
        } catch (error) {
          console.error('Error fetching assessment data:', error);
        }
      } else {
        // User is not logged in, load data from localStorage
        const storedData = localStorage.getItem('YBOCS_assessment');
        if (storedData) {
          const parsedData = JSON.parse(storedData);
          if (parsedData?.answers) {
            setAnswers(parsedData.answers);
          }
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const calculateScores = () => {
    const obsessionKeys = [
      'obsessiveThoughtsTime',
      'obsessiveThoughtsInterference',
      'obsessiveThoughtsDistress',
      'obsessiveThoughtsResistance',
      'obsessiveThoughtsControl',
    ];

    const compulsionKeys = [
      'compulsiveBehaviorsTime',
      'compulsiveBehaviorsInterference',
      'compulsiveBehaviorsDistress',
      'compulsiveBehaviorsResistance',
      'compulsiveBehaviorsControl',
    ];

    const scoreObsessions = obsessionKeys.reduce(
      (sum, key) => sum + (answers[key] || 0),
      0
    );
    const scoreCompulsions = compulsionKeys.reduce(
      (sum, key) => sum + (answers[key] || 0),
      0
    );

    const totalScore = scoreObsessions + scoreCompulsions;

    setScores({
      obsessions: scoreObsessions,
      compulsions: scoreCompulsions,
      total: totalScore,
    });
  };

  const [successMessage, setSuccessMessage] = useState('');
  const currentUser = auth.currentUser; // Get the logged-in user
  //const db = getFirestore();

  const handleChange = (e, key, type = null) => {
    const { checked, value, type: inputType } = e.target;

    setAnswers(prev => ({
      ...prev,
      [key]:
        inputType === 'checkbox'
          ? { ...prev[key], [type]: checked } // For checkboxes
          : parseInt(value, 10), // For radio buttons, convert value to number
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    calculateScores();

    const saveData = async () => {
      // Save the assessment document directly in the "assessments" collection
      const assessmentRef = doc(db, 'YBOCS_assessment', currentUser.uid); // You can use currentUser.uid as the document ID for uniqueness

      try {
        // Save data to Firestore
        await setDoc(
          assessmentRef,
          {
            answers,
            scores,
            timestamp: new Date(), // Save the current date and time
          },
          { merge: true } // Merge if document already exists
        );
        setSuccessMessage('Your assessment has been saved successfully!');
      } catch (error) {
        console.error('Error saving assessment:', error);
        alert('There was an error saving your assessment. Please try again.');
      }
    };

    const saveToLocalStorage = () => {
      localStorage.setItem(
        'YBOCS_assessment',
        JSON.stringify({ answers, timestamp: new Date() })
      );
      alert('Your assessment has been saved!');
    };

    if (currentUser) {
      await saveData(); // Save to Firestore if logged in
    } else {
      saveToLocalStorage(); // Save to localStorage if not logged in
    }
  };

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  return (
    <>
      {NavbarComponent}
      <div className='yale-brown-form'>
        <h1>Yale–Brown Obsessive–Compulsive Scale Assessment</h1>
        <p className='assessment-intro'>
          The Yale–Brown Obsessive–Compulsive Scale (Y-BOCS) is a widely used
          clinical assessment tool designed to measure the severity of
          obsessive-compulsive disorder (OCD) symptoms. It helps individuals and
          clinicians understand the impact of obsessions and compulsions on
          daily life, tracking progress over time.
          <br />
          <br />
          While completing the inventories below, please keep in mind the
          following definitions of obsessions and compulsions.
          <br />
          <br />
          <strong>OBSESSIONS</strong> are unwelcomed and distressing ideas,
          thoughts, or impulses that repeatedly enter your mind. They may seem
          to occur against your will. They may be repugnant to you, you may
          recognize them as senseless, and they may not fit your personality.
          <br />
          <br />
          <strong>
            Examples of an obsession are recurrent thoughts or impulses to do
            harm to a child even though you never would or the idea that
            household cleansers may lead to contamination and serious illness.
          </strong>
          <br />
          <br />
          Obsessions differ from worries in that worries are about possible
          negative things related to life problems that you are afraid might
          happen. For example, you may worry about failing an exam, about
          finances, health, or personal relationships. In contrast to
          obsessions, your worries don’t usually seem totally senseless,
          repugnant, or inconsistent with your personality.
          <br />
          <br />
          <strong>COMPULSIONS</strong>, on the other hand, are behaviors or acts
          that you feel driven to perform although you may recognize them as
          senseless or excessive. Usually compulsions are performed in response
          to an obsession, or according to certain rules or in a stereotyped
          fashion. At times, you may try to resist doing them but this may prove
          difficult. You may experience discomfort that does not diminish until
          the behavior is completed.
          <br />
          <br />
          <strong>
            Examples of a compulsion are the need to repeatedly check
            appliances, water faucets, and the lock on the front door before you
            can leave the house or repeated handwashing. While most compulsions
            are observable behaviors, some are unobservable mental acts, such as
            silent checking or having to recite nonsense phrases to yourself
            each time you have a bad thought.
          </strong>
          <br />
          <br />
          Given the above definitions, please read carefully each item on the
          checklist below and/or place a check mark beside each obsession and
          compulsion that you currently experience and that you have experienced
          at some time in the past.
        </p>
        <br />

        <h2>Part 1: Symptom checklist</h2>
        <p>Check all which apply to you.</p>
        <form onSubmit={handleSubmit}>
          {[
            {
              key: 'harmSelf',
              question: '1. I fear I might harm myself',
              examples:
                'Fear of eating with a knife or fork, fear of handling sharp objects, fear of walking near glass windows',
            },
            {
              key: 'harmOthers',
              question: '2. I fear I might harm other people',
              examples:
                'Fear of poisoning other people’s food, fear of harming babies, fear of pushing someone in front of a train, fear of hurting someone’s feelings, fear of being responsible by not providing assistance for some imagined catastrophe, fear of causing harm by giving bad advice',
            },
            {
              key: 'violentImages',
              question: '3. I have violent or horrific images in my mind',
              examples:
                'Images of murder, dismembered bodies, or other disgusting scenes',
            },
            {
              key: 'blurtObscenities',
              question: '4. I fear I will blurt out obscenities',
              examples:
                'Fear of shouting obscenities in public situations like church or class, fear of writing obscenities',
            },
            {
              key: 'embarrassing',
              question: '5. I fear doing something embarrassing',
              examples: 'Fear of appearing foolish in social situations',
            },
            {
              key: 'unwantedImpulse',
              question: '6. I fear I will act on an unwanted impulse',
              examples:
                'Fear of driving a car into a tree, fear of running someone over, fear of stabbing a friend',
            },
            {
              key: 'stealing',
              question: '7. I fear I will steal things',
              examples:
                'Fear of “cheating” a cashier, fear of shoplifting inexpensive items',
            },
            {
              key: 'harmByCarelessness',
              question:
                '8. I fear that I’ll harm others because I’m not careful enough',
              examples:
                'Fear of causing an accident without being aware of it (such as a hit-and-run accident)',
            },
            {
              key: 'responsibility',
              question:
                '9. I fear I’ll be responsible for something else terrible happening',
              examples:
                'Fear of causing a fire or burglary because of not being careful enough in checking the house before leaving',
            },
            {
              key: 'bodilyWaste',
              question:
                '10. I am concerned or disgusted with bodily waste or secretions',
              examples:
                'Fear of contracting AIDS, cancer, or other diseases from public rest rooms; fear of your own saliva, urine, feces, semen, or vaginal secretions',
            },
            {
              key: 'dirtGerms',
              question: '11. I am concerned with dirt or germs',
              examples:
                'Fear of picking up germs from sitting in certain chairs, shaking hands, or touching door handles',
            },
            {
              key: 'environmentalContaminants',
              question:
                '12. I am excessively concerned with environmental contaminants',
              examples:
                'Fear of being contaminated by asbestos or radon, fear of radioactive substances, fear of things associated with towns containing toxic waste sites',
            },
            {
              key: 'householdCleansers',
              question:
                '13. I am excessively concerned with certain household cleansers',
              examples:
                'Fear of poisonous kitchen or bathroom cleansers, solvents, insect spray or turpentine',
            },
            {
              key: 'animals',
              question: '14. I am excessively concerned with animals',
              examples:
                'Fear of being contaminated by touching an insect, dog, cat, or other animal',
            },
            {
              key: 'stickySubstances',
              question: '15. I am bothered by sticky substances or residues',
              examples:
                'Fear of adhesive tape or other sticky substances that may trap contaminants',
            },
            {
              key: 'illnessContamination',
              question:
                '16. I am concerned that I will get ill because of contamination',
              examples:
                'Fear of getting ill as a direct result of being contaminated (beliefs vary about how long the disease will take to appear)',
            },
            {
              key: 'contaminateOthers',
              question: '17. I am concerned that I will contaminate others',
              examples:
                'Fear of touching other people or preparing their food after you touch poisonous substances (like gasoline) or after you touch your own body',
            },
            {
              key: 'sexualThoughts',
              question:
                '18. I have forbidden or perverse sexual thoughts, images, or impulses',
              examples:
                'Unwanted sexual thoughts about strangers, family, or friends',
            },
            {
              key: 'sexualChildrenIncest',
              question:
                '19. I have sexual obsessions that involve children or incest',
              examples:
                'Unwanted thoughts about sexually molesting either your own children or other children',
            },
            {
              key: 'homosexuality',
              question: '20. I have obsessions about homosexuality',
              examples:
                'Worries like “Am I a homosexual?” or “What if I suddenly become gay?” when there is no basis for these thoughts',
            },
            {
              key: 'aggressiveSexualBehavior',
              question:
                '21. I have obsessions about aggressive sexual behavior toward other people',
              examples:
                'Unwanted images of violent sexual behavior toward adult strangers, friends, or family members',
            },
            {
              key: 'hoarding',
              question: '22. I have obsessions about hoarding or saving things',
              examples:
                'Worries about throwing away seemingly unimportant things that you might need in the future, urges to pick up and collect useless things',
            },
            {
              key: 'sacrilege',
              question: '23. I am concerned with sacrilege and blasphemy',
              examples:
                'Worries about having blasphemous thoughts, saying blasphemous things, or being punished for such things',
            },
            {
              key: 'morality',
              question: '24. I am excessively concerned with morality',
              examples:
                'Worries about always doing “the right thing,” having told a lie, or having cheated someone',
            },
            {
              key: 'symmetry',
              question: '25. I have obsessions about symmetry or exactness',
              examples:
                'Worries about papers and books being properly aligned, worries about calculations or handwriting being perfect',
            },
            {
              key: 'rememberThings',
              question:
                '26. I feel that I need to know or remember certain things',
              examples:
                'Belief that you need to remember insignificant things like license plate numbers, the names of actors on television shows, old telephone numbers, bumper stickers or t-shirt slogans',
            },
            {
              key: 'fearSayingThings',
              question: '27. I fear saying certain things',
              examples:
                'Fear of saying certain words (such as “thirteen”) because of superstitions, fear of saying something that might be disrespectful to a dead person, fear of using words with an apostrophe (because this denotes possession)',
            },
            {
              key: 'fearNotSayingRight',
              question: '28. I fear not saying just the right thing',
              examples:
                'Fear of having said the wrong thing, fear of not using the “perfect” word',
            },
            {
              key: 'fearLosingThings',
              question: '29. I fear losing things',
              examples:
                'Worries about losing a wallet or other unimportant objects, like a scrap of note paper',
            },
            {
              key: 'intrusiveImages',
              question:
                '30. I am bothered by intrusive (neutral) mental images',
              examples: 'Random, unwanted images in your mind',
            },
            {
              key: 'intrusiveSounds',
              question:
                '31. I am bothered by intrusive mental nonsense sounds, words or music',
              examples:
                'Words, songs, or music in your mind that you can’t stop',
            },
            {
              key: 'noises',
              question: '32. I am bothered by certain sounds or noises',
              examples:
                'Worries about the sounds of clocks ticking loudly or voices in another room that may interfere with sleeping',
            },
            {
              key: 'luckyUnluckyNumbers',
              question: '33. I have lucky and unlucky numbers',
              examples:
                'Worries about common numbers (like thirteen) that may cause you to perform activities a certain number of times or to postpone an action until a certain lucky hour of the day',
            },
            {
              key: 'colorsSignificance',
              question: '34. Certain colors have special significance to me',
              examples:
                'Fear of using objects of certain colors (e.g. black may be associated with death, red with blood or injury)',
            },
            {
              key: 'superstitiousFears',
              question: '35. I have superstitious fears',
              examples:
                'Fear of passing a cemetery, hearse, or black cat; fear of omens associated with death',
            },
            {
              key: 'illnessConcern',
              question: '36. I am concerned with illness or disease',
              examples:
                'Worries that you have an illness like cancer, heart disease or AIDS, despite reassurance from doctors that you do not',
            },
            {
              key: 'appearanceConcern',
              question:
                '37. I am excessively concerned with a part of my body or an aspect of my appearance (dysmorphophobia)',
              examples:
                'Worries that your face, ears, nose, eyes, or another part of your body is hideous, ugly, despite reassurances to the contrary',
            },
            {
              key: 'handWashing',
              question:
                '38. I wash my hands excessively or in a ritualized way',
              examples:
                'Washing your hands many times a day or for long periods of time after touching, or thinking that you have touched, a contaminated object. This may include washing the entire length of your arms',
            },
            {
              key: 'showeringGrooming',
              question:
                '39. I have excessive or ritualized showering, bathing, tooth brushing, grooming, or toilet routines',
              examples:
                'Taking showers or baths or performing other bathroom routines that may last for several hours. If the sequence is interrupted, the entire process may have to be restarted',
            },
            {
              key: 'cleaningItems',
              question:
                '40. I have compulsions that involve cleaning household items or other inanimate objects',
              examples:
                'Excessive cleaning of faucets, toilets, floors, kitchen counters, or kitchen utensils',
            },
            {
              key: 'avoidingContaminants',
              question:
                '41. I do other things to prevent or remove contact with contaminants',
              examples:
                'Asking family members to handle or remove insecticides, garbage, gasoline cans, raw meat, paints, varnish, drugs in the medicine cabinet, or kitty litter. If you can’t avoid these things, you may wear gloves to handle them, such as when using a self-service gas pump',
            },
            {
              key: 'checkingHarm',
              question: '42. I check that I did not harm others',
              examples:
                'Checking that you haven’t hurt someone without knowing it. You may ask others for reassurance or call or text someone to make sure everything is all right',
            },
            {
              key: 'checkingHarm43',
              question: '43. I fear saying certain things',
              examples:
                'Fear of saying certain words (such as “thirteen”) because of superstitions, fear of saying something that might be disrespectful to a dead person, fear of using words with an apostrophe (because this denotes possession)',
            },
            {
              key: 'checkingTerrible',
              question: '44. I check that nothing terrible happened',
              examples:
                'Searching the newspaper or listening to the radio or television for news about some catastrophe that you believe you caused. You may also ask people for reassurance that you didn’t cause an accident',
            },
            {
              key: 'checkingMistakes',
              question: '45. I check that I did not make a mistake',
              examples:
                'Repeated checking of door locks, stoves, electrical outlets, before leaving home; repeated checking while reading, writing, or doing simple calculations to make sure that you didn’t make a mistake (you can’t be certain that you didn’t)',
            },
            {
              key: 'physicalCondition',
              question:
                '46. I check some aspect of my physical condition tied to my obsessions about my body',
              examples:
                'Seeking reassurance from friends or doctors that you aren’t having a heart attack or getting cancer; repeatedly taking pulse, blood pressure, or temperature; checking your appearance in a mirror, looking for ugly features',
            },
            {
              key: 'repeatingActivities',
              question: '47. I need to repeat routine activities',
              examples:
                'Repeating activities like turning appliances on and off, combing your hair, going in and out of a doorway, or looking in a particular direction; not feeling comfortable unless you do these things the “right” way or the “right” number of times',
            },
            {
              key: 'repeatingActivities47',
              question: '48. I reread or rewrite things',
              examples:
                'Taking hours to read a few pages in a book or to write a short letter because you get caught in a cycle of reading and rereading; worrying that you didn’t understand something you just read; searching for a “perfect” word or phrase; having obsessive thoughts about the shape of certain printed letters in a book',
            },
            {
              key: 'countingCompulsions',
              question: '49. I have counting compulsions',
              examples:
                'Counting objects like ceiling or floor tiles, books in a bookcase, nails in a wall, or even grains of sand on a beach; counting when you repeat certain activities, like washing',
            },
            {
              key: 'orderingArranging',
              question: '50. I have ordering or arranging compulsions',
              examples:
                'Straightening paper and pens on a desktop or books in a bookcase, wasting hours arranging things in your house in “order” and then becoming very upset if this order is disturbed',
            },
            {
              key: 'hoardingCollecting',
              question: '51. I have compulsions to hoard or collect things',
              examples:
                'Saving old newspapers, notes, cans, paper towels, wrappers and empty bottles for fear that if you throw them away you may need them; picking up useless objects from the street or from garbage cans',
            },
            {
              key: 'mentalRituals',
              question:
                '52. I have mental rituals (other than checking/counting)',
              examples:
                'Performing rituals in your head, like saying prayers or thinking a “good” thought to undo a “bad” thought. These are different from obsessions, because you perform these rituals intentionally to reduce anxiety or feel better',
            },
            {
              key: 'confessing',
              question: '53. I need to tell, ask, or confess',
              examples:
                'Asking other people to reassure you, confessing to wrong behaviors you never even did, believing that you have to tell other people certain words to feel better',
            },
            {
              key: 'touching',
              question: '54. I need to touch, tap, or rub things',
              examples:
                'Giving in to the urge to touch rough surfaces, like wood, or hot surfaces, like a stove top; giving in to the urge to lightly touch other people; believing you need to touch an object like a telephone to prevent an illness in your family',
            },
            {
              key: 'preventingHarm',
              question:
                '55. I take measures (other than checking) to prevent harm or terrible consequences to myself or family',
              examples:
                'Staying away from sharp or breakable objects, such as knives, scissors, and fragile glass',
            },
            {
              key: 'ritualizedEating',
              question: '56. I have ritualized eating behaviors',
              examples:
                'Arranging your food, knife, and fork in a particular order before being able to eat, eating according to a strict ritual, not being able to eat until the hands of a clock point exactly at a certain time',
            },
            {
              key: 'superstitiousBehaviors',
              question: '57. I have superstitious behaviors',
              examples:
                'Not taking a bus or train if its number contains an “unlucky” number (like thirteen), staying in your house on the thirteenth of the month, throwing away clothes you wore while passing a funeral home or cemetery',
            },
            {
              key: 'hairPulling',
              question: '58. I pull my hair out (trichotillomania)',
              examples:
                'Pulling hair from your scalp, eyelids, eyelashes, or pubic areas, using your fingers or tweezers. You may produce bald spots that require you to wear a wig, or you may pluck your eyebrows or eyelids smooth',
            },
          ].map(({ key, question, examples }) => (
            <div key={key} className='question-block'>
              <p>
                <strong>{question}</strong>
                <br />
                <em>EXAMPLES: {examples}</em>
              </p>
              <label>
                Past&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <input
                  type='checkbox'
                  checked={answers[key]?.past || false} // Ensure safe access with optional chaining
                  onChange={e => handleChange(e, key, 'past')}
                />
              </label>

              <label>
                Current
                <input
                  type='checkbox'
                  checked={answers[key]?.current || false} // Ensure safe access with optional chaining
                  onChange={e => handleChange(e, key, 'current')}
                />
              </label>
            </div>
          ))}
          <br />
          <h2>Part 2: Scaling</h2>
          <p className='assessment-intro'>
            Review the obsessions you checked here above to help you answer the
            first five questions and the compulsions you checked to answer the
            questions 6 to 10. Think about the times when these symptoms were at
            their worst in the last 3-6 months (including today), and check one
            answer for each question.
          </p>
          <br />

          {/* Part 2: Scaling */}
          {[
            {
              key: 'obsessiveThoughtsTime',
              question: '1. Time occupied by obsessive thoughts',
              options: [
                { label: 'None', value: 0 },
                {
                  label: 'Less than 1 hr/day or occasional occurrence',
                  value: 1,
                },
                { label: '1 to 3 hrs/day or frequent', value: 2 },
                {
                  label:
                    'Greater than 3 and up to 8 hrs/day or very frequent occurrence',
                  value: 3,
                },
                {
                  label: 'Greater than 8 hrs/day or nearly constant occurrence',
                  value: 4,
                },
              ],
            },
            {
              key: 'obsessiveThoughtsInterference',
              question: '2. Interference due to obsessive thoughts',
              options: [
                { label: 'None', value: 0 },
                {
                  label:
                    'Slight interference with social or other activities, but overall performance not impaired',
                  value: 1,
                },
                {
                  label:
                    'Definite interference with social or occupational performance, but still manageable',
                  value: 2,
                },
                {
                  label:
                    'Causes substantial impairment in social or occupational performance',
                  value: 3,
                },
                { label: 'Incapacitating', value: 4 },
              ],
            },
            {
              key: 'obsessiveThoughtsDistress',
              question: '3. Distress associated with obsessive thoughts',
              options: [
                { label: 'None', value: 0 },
                { label: 'Not too disturbing', value: 1 },
                { label: 'Disturbing, but still manageable', value: 2 },
                { label: 'Very disturbing', value: 3 },
                { label: 'Near constant and disabling distress', value: 4 },
              ],
            },
            {
              key: 'obsessiveThoughtsResistance',
              question: '4. Resistance against obsessions',
              options: [
                { label: 'Try to resist all the time', value: 0 },
                { label: 'Try to resist most of the time', value: 1 },
                { label: 'Make some effort to resist', value: 2 },
                {
                  label:
                    'Yield to all obsessions without attempting to control them, but with some reluctance',
                  value: 3,
                },
                {
                  label: 'Completely and willingly yield to all obsessions',
                  value: 4,
                },
              ],
            },
            {
              key: 'obsessiveThoughtsControl',
              question: '5. Degree of control over obsessive thoughts',
              options: [
                { label: 'Complete control', value: 0 },
                {
                  label:
                    'Usually able to stop or divert obsessions with some effort and concentration',
                  value: 1,
                },
                {
                  label: 'Sometimes able to stop or divert obsessions',
                  value: 2,
                },
                {
                  label:
                    'Rarely successful in stopping or dismissing obsessions, can only divert attention with difficulty',
                  value: 3,
                },
                {
                  label:
                    'Obsessions are completely involuntary, rarely able to even momentarily alter obsessive thinking.',
                  value: 4,
                },
              ],
            },
            {
              key: 'compulsiveBehaviorsTime',
              question: '6. Time spent performing compulsive behaviors',
              options: [
                { label: 'None', value: 0 },
                {
                  label:
                    'Less than 1 hr/day, or occasional performance of compulsive behaviors',
                  value: 1,
                },
                {
                  label:
                    'From 1 to 3 hrs/day, or frequent performance of compulsive behaviors',
                  value: 2,
                },
                {
                  label:
                    'More than 3 and up to 8 hrs/day, or very frequent performance of compulsive behaviors',
                  value: 3,
                },
                {
                  label:
                    'More than 8 hrs/day, or near constant performance of compulsive behaviors (too numerous to count)',
                  value: 4,
                },
              ],
            },
            {
              key: 'compulsiveBehaviorsInterference',
              question: '7. Interference due to compulsive behaviors',
              options: [
                { label: 'None', value: 0 },
                {
                  label:
                    'Slight interference with social or other activities, but overall performance not impaired',
                  value: 1,
                },
                {
                  label:
                    'Definite interference with social or occupational performance, but still manageable',
                  value: 2,
                },
                {
                  label:
                    'Causes substantial impairment in social or occupational performance',
                  value: 3,
                },
                { label: 'Incapacitating', value: 4 },
              ],
            },
            {
              key: 'compulsiveBehaviorsDistress',
              question: '8. Distress associated with compulsive behavior',
              options: [
                { label: 'None', value: 0 },
                {
                  label: 'Only slightly anxious if compulsions prevented',
                  value: 1,
                },
                {
                  label:
                    'Anxiety would mount but remain manageable if compulsions prevented',
                  value: 2,
                },
                {
                  label:
                    'Prominent and very disturbing increase in anxiety if compulsions interrupted',
                  value: 3,
                },
                {
                  label:
                    'Incapacitating anxiety from any intervention aimed at modifying activity',
                  value: 4,
                },
              ],
            },
            {
              key: 'compulsiveBehaviorsResistance',
              question: '9. Resistance against compulsions',
              options: [
                { label: 'Always try to resist', value: 0 },
                { label: 'Try to resist most of the time', value: 1 },
                { label: 'Make some effort to resist', value: 2 },
                {
                  label:
                    'Yield to almost all compulsions without attempting to control them, but with some reluctance',
                  value: 3,
                },
                {
                  label: 'Completely and willingly yield to all compulsions',
                  value: 4,
                },
              ],
            },
            {
              key: 'compulsiveBehaviorsControl',
              question: '10. Degree of control over compulsive behavior',
              options: [
                { label: 'Complete control', value: 0 },
                {
                  label:
                    'Pressure to perform the behavior but usually able to exercise voluntary control over it',
                  value: 1,
                },
                {
                  label:
                    'Strong pressure to perform behavior, can control it only with difficulty',
                  value: 2,
                },
                {
                  label:
                    'Very strong drive to perform behavior, must be carried to completion, can only delay with difficulty',
                  value: 3,
                },
                {
                  label:
                    'Drive to perform behavior experienced as completely involuntary and over-powering, rarely able to even momentarily delay activity.',
                  value: 4,
                },
              ],
            },
          ].map(({ key, question, options }) => (
            <div key={key} className='question-block'>
              <p>
                <strong>{question}</strong>
              </p>
              {options.map((option, index) => (
                <label key={index}>
                  <input
                    type='radio'
                    name={key} // Group radio buttons
                    value={option.value} // Correctly access option.value
                    checked={answers[key] === option.value} // Correctly check against option.value
                    onChange={e => handleChange(e, key)} // Handle change for radio buttons
                  />
                  {option.label} {/* Correctly access option.label */}
                </label>
              ))}
            </div>
          ))}
          <br />
          <button type='submit'>Save Scores & Assessment</button>
        </form>
        <br />
        {successMessage && <p>{successMessage}</p>}

        <div className='score-container'>
          <div className='score-box'>
            <h2>Obsessions</h2>
            <p className='score-value'>{scores.obsessions}</p>
          </div>
          <div className='score-box'>
            <h2>Compulsions</h2>
            <p className='score-value'>{scores.compulsions}</p>
          </div>
          <div className='score-box'>
            <h2>Y-BOCS</h2>
            <p className='score-value'>{scores.total}</p>
            <p className='score-category'>
              {scores.total <= 7
                ? 'No Symptoms'
                : scores.total <= 15
                ? 'Mild Symptoms'
                : scores.total <= 23
                ? 'Moderate Symptoms'
                : scores.total <= 31
                ? 'Severe Symptoms'
                : 'Extreme Symptoms'}
            </p>
          </div>
          <div className='score-box' style={{ width: '250px' }}>
            <h2>Y-BOCS Interpretation</h2>
            <p className='score-category' style={{ textAlign: 'left' }}>
              <strong>00–07:</strong> Subclinical symptom
              <br />
              <strong>08–15:</strong> Mild symptoms
              <br />
              <strong>16–23:</strong> Moderate symptoms
              <br />
              <strong>24–31:</strong> Severe symptoms
              <br />
              <strong>32–40:</strong> Extreme symptoms
            </p>
          </div>
        </div>
        <br />
        <p
          className='assessment-intro'
          style={{ fontSize: '0.8rem', lineHeight: '1.2' }}
        >
          <strong>Acknowledgments:</strong> The Y-BOCS was developed by Goodman,
          W.K., Price, L.H., Rassmussen, S.A., et al. (1989). The Yale-Brown
          Obsessive-Compulsive Scale (Y-BOCS) Part 1: Development, use and
          reliability. <i>Archives of General Psychiatry</i>,46 1006-1011. It
          was modified for computer administration by John Greist and
          associates, (1992). A computer administered version of the Yale-Brown
          Obsessive Compulsive Scale. <i>Psychological Assessment</i>, 4
          329-332.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default YaleBrownForm;
