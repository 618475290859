import React, { useEffect, useState } from 'react';
import {
  getFirestore,
  doc,
  addDoc,
  collection,
  getDoc,
  getDocs,
  query,
  orderBy,
  limit,
} from 'firebase/firestore';
import { auth, db } from './config/firebaseConfig'; // Firebase setup
import { onAuthStateChanged } from 'firebase/auth';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts'; // Import Recharts
import './YaleBrownForm.css';
import Navbar from './Navbar';
import NavbarClient from './NavbarClient';
import NavbarTherapist from './NavbarTherapist'; // Import different navbars
import NavbarIndividual from './NavbarIndividual';
import Footer from './Footer';

const PHQ9 = () => {
  const [userRole, setUserRole] = useState(''); // State to track user role
  const [scores, setScores] = useState({ totalScore: 0 });

  const [answers, setAnswers] = useState({
    question1: 0,
    question2: 0,
    question3: 0,
    question4: 0,
    question5: 0,
    question6: 0,
    question7: 0,
    question8: 0,
    question9: 0,
  });

  const [scoreHistory, setScoreHistory] = useState([]); // Store the historical scores
  const currentUser = auth.currentUser; // Get the logged-in user
  const [isLoggedIn, setIsLoggedIn] = useState(false); // State to track login status
  const [loading, setLoading] = useState(true); // State to track loading

  useEffect(() => {
    // Listen for changes to the user's login state
    const unsubscribe = onAuthStateChanged(auth, async user => {
      setIsLoggedIn(!!user); // Set to true if user exists, otherwise false

      if (user) {
        // Fetch user role from Firestore
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists()) {
          setUserRole(userDoc.data().role);
          //console.log('user role: ', userDoc.data().role);
        }
      }
    });

    // Clean up the listener on component unmount
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    // Trigger calculateScores when answers state is updated
    calculateScores();
  }, [answers]);

useEffect(() => {
  const fetchForms = async () => {
    if (currentUser) {
      const userDocRef = doc(db, 'PHQ9_assessment', currentUser.uid); // Reference to the user document
      const formsCollectionRef = collection(userDocRef, 'PHQ9_forms'); // Sub-collection for the forms

      try {
        // Query the sub-collection to get all forms and order by timestamp
        const q = query(formsCollectionRef, orderBy('timestamp', 'asc')); // Use 'asc' to get the oldest forms first
        const querySnapshot = await getDocs(q);
        //console.log('lst form: ',querySnapshot);

        if (!querySnapshot.empty) {
          const formsData = [];
          querySnapshot.forEach(doc => {
            const data = doc.data();
            formsData.push({
              timestamp: data.timestamp.toDate(),
              totalScore: data.scores.totalScore,
            });
            //console.log('formsData: ',formsData);
          });
          //console.log('formsData1: ',formsData);
          setScoreHistory(formsData); // Store the historical scores
          const mostRecentForm = formsData[formsData.length - 1]; // Get the most recent form
          setAnswers(mostRecentForm.answers || {}); // Set the most recent form's answers
          setScores(mostRecentForm.scores || { totalScore: 0 }); // Set the most recent form's scores
        }
      } catch (error) {
        console.error('Error fetching forms:', error);
      }
    }
    setLoading(false); // Set loading to false once data is fetched
  };

  fetchForms(); // Fetch the most recent form and score
}, [currentUser]);

  const [successMessage, setSuccessMessage] = useState('');
  
  //const db = getFirestore();

  const handleChange = (e, key) => {
    const { value } = e.target;

    // Update the answers state
    setAnswers(prev => ({
      ...prev,
      [key]: parseInt(value, 10),
    }));
  };

  const calculateScores = () => {
    const obsessionKeys = [
      'question1',
      'question2',
      'question3',
      'question4',
      'question5',
      'question6',
      'question7',
      'question8',
      'question9',
    ];

    const totalScore = obsessionKeys.reduce(
      (sum, key) => sum + (answers[key] || 0),
      0
    );

    setScores({
      totalScore,
    });
  };

  // Recalculate the score whenever `answers` change
  useEffect(() => {
    calculateScores();
  }, [answers]);

  const handleSubmit = async e => {
    e.preventDefault();
    calculateScores();

    const saveData = async () => {
      // Reference the user's "GAD7_forms" sub-collection
      const userDocRef = doc(db, 'PHQ9_assessment', currentUser.uid); // Reference to the user document
      const formsCollectionRef = collection(userDocRef, 'PHQ9_forms'); // Sub-collection for the forms

      try {
        // Add the new form as a document in the user's sub-collection
        await addDoc(formsCollectionRef, {
          answers,
          scores,
          timestamp: new Date(), // Save the current date and time
        });

        alert('Your assessment has been saved successfully!');
        // Fetch the updated score history after saving the new form
      fetchForms(); // Fetch and update score history after saving the form
      } catch (error) {
        console.error('Error saving assessment:', error);
        alert('There was an error saving your assessment. Please try again.');
      }
    };

    const saveToLocalStorage = () => {
      localStorage.setItem(
        'PHQ9_assessment',
        JSON.stringify({ answers, timestamp: new Date() })
      );
      alert('Your assessment has been saved!');
    };

    if (currentUser) {
      await saveData(); // Save to Firestore if logged in
    } else {
      saveToLocalStorage(); // Save to localStorage if not logged in
    }
  };

    const fetchForms = async () => {
  if (currentUser) {
    const userDocRef = doc(db, 'PHQ9_assessment', currentUser.uid); // Reference to the user document
    const formsCollectionRef = collection(userDocRef, 'PHQ9_forms'); // Sub-collection for the forms

    try {
      // Query the sub-collection to get all forms and order by timestamp
      const q = query(formsCollectionRef, orderBy('timestamp', 'asc'));
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const formsData = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();
          formsData.push({
            timestamp: data.timestamp.toDate(),
            totalScore: data.scores.totalScore,
          });
        });
        setScoreHistory(formsData); // Store the historical scores
        const mostRecentForm = formsData[formsData.length - 1];
        setAnswers(mostRecentForm.answers || {});
        setScores(mostRecentForm.scores || { totalScore: 0 });
      }
    } catch (error) {
      console.error('Error fetching forms:', error);
    }
  }
};

  // Select Navbar based on user role
  let NavbarComponent;
  if (userRole === 'client') {
    NavbarComponent = <NavbarClient />;
  } else if (userRole === 'therapist') {
    NavbarComponent = <NavbarTherapist />;
  } else if (userRole === 'individual') {
    NavbarComponent = <NavbarIndividual />;
  } else {
    NavbarComponent = <Navbar />;
  }

  if (loading) {
    return <div>Loading...</div>; // Show loading indicator while waiting for data
  }

  return (
    <>
      {NavbarComponent}
      <div className='yale-brown-form'>
        <h1>PHQ-9 (Patient Health Questionnaire-9)</h1>
        <p className='assessment-intro'>
          The <strong>PHQ-9 (Patient Health Questionnaire-9)</strong> is a
          widely used tool for screening, diagnosing, and measuring the severity
          of depression. It consists of 9 questions that assess the frequency of
          symptoms related to depression over the past two weeks. The
          questionnaire is often used in clinical settings, including primary
          care, mental health, and other healthcare settings, to help healthcare
          providers assess the level of depression in a patient.
        </p>
        <br />
        
        {/* Display message if not logged in */}
        {!isLoggedIn && (
          <div className='assessment-intro'>
          <h2>Historical PHQ-9 Scores</h2>
            <p>This feature is only available for registered users.</p>
          </div>
        )}
{isLoggedIn && (
        <div className='assessment-intro'>
          <h2>Historical PHQ-9 Scores</h2>
          <div style={{ width: '100%', height: 300 }}>
            <ResponsiveContainer width='100%' height='100%'>
              <LineChart data={scoreHistory}>
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis
                  dataKey='timestamp'
                  tickFormatter={timestamp => timestamp.toLocaleDateString()}
                />
                <YAxis />
                <Tooltip
                  contentStyle={{ backgroundColor: 'black', color: 'white' }}
                  labelFormatter={timestamp => {
                    // Format the timestamp to display the desired format "Thu Jan 23 2025"
                    return new Date(timestamp).toDateString();
                  }}
                />
                <Legend />
                <Line
                  type='monotone'
                  dataKey='totalScore'
                  stroke='#8884d8'
                  name='PHQ-9 Scores'
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
        )}
        <br/>
        <p>
          How often have you been bothered by the following over the past 2
          weeks?
        </p>
        <form onSubmit={handleSubmit}>
          {[
            {
              key: 'question1',
              question: '1. Little interest or pleasure in doing things?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question2',
              question: '2. Feeling down, depressed, or hopeless?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question3',
              question:
                '3. Trouble falling or staying asleep, or sleeping too much?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question4',
              question: '4. Feeling tired or having little energy?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question5',
              question: '5. Poor appetite or overeating?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question6',
              question:
                '6. Feeling bad about yourself — or that you are a failure or have let yourself or your family down?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question7',
              question:
                '7. Trouble concentrating on things, such as reading the newspaper or watching television?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question8',
              question:
                '8. Moving or speaking so slowly that other people could have noticed? Or so fidgety or restless that you have been moving a lot more than usual?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question9',
              question:
                '9. Thoughts that you would be better off dead, or thoughts of hurting yourself in some way?',
              options: [
                { label: 'Not at all', value: 0 },
                { label: 'Several days', value: 1 },
                { label: 'More than half the days', value: 2 },
                { label: 'Nearly every day', value: 3 },
              ],
            },
            {
              key: 'question10',
              question:
                'If you checked off any problems, how difficult have these problems made it for you to do your work, take care of things at home, or get along with other people?',
              options: [
                { label: 'Not difficult at all', value: 0 },
                { label: 'Somewhat difficult', value: 1 },
                { label: 'Very difficult', value: 2 },
                { label: 'Extremely difficult', value: 3 },
              ],
            },
          ].map(({ key, question, options }) => (
            <div key={key} className='question-block'>
              <p>
                <strong>{question}</strong>
              </p>
              {options.map((option, index) => (
                <label key={index}>
                  <input
                    type='radio'
                    name={key} // Group radio buttons
                    value={option.value} // Correctly access option.value
                    checked={answers[key] === option.value} // Correctly check against option.value
                    onChange={e => handleChange(e, key)} // Handle change for radio buttons
                  />
                  {option.label} {/* Correctly access option.label */}
                </label>
              ))}
            </div>
          ))}
          <br />
          {isLoggedIn && <button type='submit'>Save Score & Assessment</button>}
        </form>
        <br />
        {successMessage && <p>{successMessage}</p>}

        <div className='score-container'>
          <div className='score-box'>
            <h2>PHQ-9</h2>
            <p className='score-value'>{scores.totalScore}</p>
            <p className='score-category'>
              {scores.totalScore <= 4
                ? 'Minimal Depression'
                : scores.totalScore <= 9
                ? 'Mild Depression'
                : scores.totalScore <= 14
                ? 'Moderate Depression'
                : scores.totalScore <= 19
                ? 'Moderately Severe Depression'
                : 'Severe Depression'}
            </p>
          </div>
          <div className='score-box' style={{ width: '310px' }}>
            <h2>PHQ-9 Interpretation</h2>
            <p className='score-category' style={{ textAlign: 'left' }}>
              <strong>00–04:</strong> Minimal Depression
              <br />
              <strong>05–09:</strong> Mild Depression
              <br />
              <strong>10–14:</strong> Moderate Depression
              <br />
              <strong>15–19:</strong> Moderately Severe Depression
              <br />
              <strong>20–27:</strong> Severe Depression
            </p>
          </div>
        </div>
        <br />
      </div>
      <Footer />
    </>
  );
};

export default PHQ9;
