import React from 'react';
import BlogPost from './BlogPost';

const PostOCDExperts = () => {
  return (
    <div>
      <BlogPost
        title='50 Influential Experts Shaping OCD Treatment'
        date='January 12, 2025'
        author='Olivier'
        content={
          <>
            <p>
              Thanks to the dedication and innovation of mental health
              professionals worldwide, treatment has advanced significantly.
              This list highlights 50 influential professionals who have shaped
              OCD treatment, offering insights into their groundbreaking
              contributions.
            </p>
            <p>
              From pioneering therapies like ERP to cutting-edge neuroscience
              and advocacy, these individuals have made significant
              contributions to the understanding and treatment of OCD through
              clinical practice, research, and the development of therapeutic
              interventions. Their work inspires hope and brings effective
              treatment to those in need.
            </p>
            <p>
              Stay tuned for detailed profiles of each of them in the upcoming
              blog posts! We'll explore their work, achievements, and the impact
              they’ve made on individuals living with OCD.
            </p>
            <br />
            <ol>
              <li>
                <strong>Dr. Wayne Goodman</strong>: Co-developer of the{' '} 
                <a href='https://www.ocdserenity.com/yale-brown'>Yale-Brown Obsessive Compulsive Scale</a> (Y-BOCS), a widely used
                tool for assessing OCD severity.
              </li>
              <li>
                <strong>Dr. Edna Foa</strong>: Renowned for her work in
                developing prolonged <a href='https://www.ocdserenity.com/hierarchy-builder'>exposure therapy</a> for anxiety disorders,
                including OCD.
              </li>
              <li>
                <strong>Dr. Carolyn I. Rodriguez</strong>: Known for pioneering
                research on rapid-acting treatments for OCD, such as <a href='https://neuroscience.stanford.edu/news/ocd-and-ketamine'>ketamine</a>.
              </li>
              <li>
                <strong>Dr. Jonathan Abramowitz</strong>: Expert in
                cognitive-behavioral therapy for OCD and anxiety disorders, with
                extensive research on treatment outcomes.
              </li>
              <li>
                <strong>Dr. Daniel A. Geller</strong>: Specializes in pediatric
                OCD and has contributed significantly to understanding its
                genetic components.
              </li>
              <li>
                <strong>Dr. Gerd Kvale and Dr. Bjarne Hansen</strong>: Developed
                the <a href='https://iocdf.org/expert-opinions/the-bergen-4-day-ocd-treatment/'>Bergen 4-Day OCD Treatment</a>, an intensive exposure-based
                therapy program.
              </li>
              <li>
                <strong>Dr. Sabine Wilhelm</strong>: Director of the <a href='https://mghocd.org/'>Center for
                OCD and Related Disorders</a>Center for
                OCD and Related Disorders at Massachusetts General
                Hospital/Harvard Medical School.
              </li>
              <li>
                <strong>Dr. Helen Blair Simpson</strong>: Director of the <a href='https://www.columbiadoctors.org/treatments-conditions/obsessive-compulsive-disorder-ocd'>Center
                for Obsessive-Compulsive and Related Disorders</a> at Columbia
                University.
              </li>
              <li>
                <strong>Dr. Steven Rasmussen</strong>: Known for his research on
                the neurobiology and treatment of OCD.
              </li>
              <li>
                <strong>Dr. Gerald Nestadt</strong>: Director of the <a href='https://www.hopkinsmedicine.org/psychiatry/specialty-areas/obsessive-compulsive-disorder'>Johns
                Hopkins OCD Clinic</a>, focusing on the genetics and epidemiology of
                OCD.
              </li>
              <li>
                <strong>Dr. Eric Hollander</strong>: Expert in OCD and related
                disorders, with contributions to understanding the spectrum of
                obsessive-compulsive behaviors.
              </li>
              <li>
                <strong>Dr. Michael Jenike</strong>: Founder of the <a href='https://www.mcleanhospital.org/treatment/ocd-institute'>OCD
                Institute at McLean Hospital</a>, a leading center for treatment and
                research.
              </li>
              <li>
                <strong>Dr. John March</strong>: Specializes in pediatric OCD
                and has developed treatment protocols for children and
                adolescents.
              </li>
              <li>
                <strong>Dr. Susan Swedo</strong>: Known for her work on{' '} 
                <a href='https://www.nimh.nih.gov/health/publications/pandas'>Pediatric Autoimmune Neuropsychiatric Disorders Associated with
                Streptococcal infections</a> (PANDAS) and its relation to OCD.
              </li>
              <li>
                <strong>Dr. Paul Salkovskis</strong>: Clinical psychologist
                recognized for his cognitive-behavioral models of OCD.
              </li>
              <li>
                <strong>Dr. Gail Steketee</strong>: Expert on hoarding disorder,
                often associated with OCD, and co-developer of specialized
                treatments.
              </li>
              <li>
                <strong>Dr. Bruce Hyman</strong>: Specializes in treating OCD
                using cognitive-behavioral therapy and has authored self-help
                books on the subject.
              </li>
              <li>
                <strong>Dr. Reid Wilson</strong>: Known for innovative
                approaches to anxiety disorders, including OCD, emphasizing the
                acceptance of uncertainty.
              </li>
              <li>
                <strong>Dr. Fred Penzel</strong>: Executive director of <a href='https://www.wsps.info/ocd-and-related-subjects'>Western
                Suffolk Psychological Services</a>, specializing in OCD and related
                disorders.
              </li>
              <li>
                <strong>Dr. Tamar Chansky</strong>: Founder of the <a href='https://childrenscenterocdandanxiety.com/'>Children's
                and Adult Center for OCD and Anxiety</a>, focusing on treatment
                across the lifespan.
              </li>
              <li>
                <strong>Dr. Gregory S. Chasson</strong>: Expert in hoarding
                disorder and OCD treatment, with a focus on cognitive-behavioral
                therapy.
              </li>
              <li>
                <strong>Dr. Kimberley Quinlan</strong>: Author and therapist
                specializing in Exposure and Response Prevention (ERP).
              </li>
              <li>
                <strong>Dr. Kevin Chapman</strong>: Director of the <a href='https://kycards.com/'>Kentucky
                Center for Anxiety and Related Disorders</a>.
              </li>
              <li>
                <strong>Dr. Monnica Williams</strong>: Known for her work on
                racial and cultural considerations in OCD.
              </li>
              <li>
                <strong>Dr. Jenny Yip</strong>: Founder of the <a href='https://renewedfreedomcenter.com/'>Renewed Freedom
                Center</a>, focusing on OCD and anxiety disorders.
              </li>
              <li>
                <strong>Dr. Ashley Smith</strong>: Specialist in ERP and author
                of tools for OCD management.
              </li>
              <li>
                <strong>Dr. Patricia Zurita Ona</strong>: Author and therapist
                specializing in ERP and ACT (Acceptance and Commitment Therapy).
              </li>
              <li>
                <strong>Dr. Shala Nicely</strong>: Co-author of Everyday
                Mindfulness for OCD and ERP specialist.
              </li>
              <li>
                <strong>Dr. David Veale</strong>: UK-based psychiatrist
                specializing in OCD and body dysmorphic disorder (BDD).
              </li>
              <li>
                <strong>Dr. Eli Lebowitz</strong>: Expert in pediatric OCD and
                developer of <a href='https://www.spacetreatment.net/'>SPACE</a> (Supportive Parenting for Anxious Childhood
                Emotions).
              </li>
              <li>
                <strong>Dr. Katherine Dahlsgaard</strong>: Expert in pediatric
                anxiety and OCD, with a focus on ERP.
              </li>
              <li>
                <strong>Dr. Marty Franklin</strong>: Renowned researcher and
                clinician specializing in OCD treatment.
              </li>
              <li>
                <strong>Dr. Eric Storch</strong>: Known for his work on
                cognitive-behavioral therapy for OCD, particularly in children.
              </li>
              <li>
                <strong>Dr. Adam Radomsky</strong>: Researcher focused on OCD
                and safety behaviors.
              </li>
              <li>
                <strong>Dr. Sarah Hughes</strong>: Specialist in OCD treatment
                and ERP therapy.
              </li>
              <li>
                <strong>Dr. Michael Twohig</strong>: ACT expert and author
                focusing on OCD and anxiety disorders.
              </li>
              <li>
                <strong>Dr. Jon Grayson</strong>: Author of Freedom from
                Obsessive-Compulsive Disorder and leading OCD therapist.
              </li>
              <li>
                <strong>Dr. Robin Zasio</strong>: Featured expert on Hoarders,
                focusing on OCD and hoarding treatments.
              </li>
              <li>
                <strong>Dr. Christina Taylor</strong>: Clinical psychologist
                specializing in ERP for OCD.
              </li>
              <li>
                <strong>Dr. Christopher Pittenger</strong>: Neuroscientist
                studying OCD's underlying biological mechanisms.
              </li>
              <li>
                <strong>Dr. Lisa Coyne</strong>: Specialist in ACT for OCD and
                related disorders.
              </li>
              <li>
                <strong>Dr. Brian Olsen</strong>: Known for integrating ERP with
                mindfulness for OCD treatment.
              </li>
              <li>
                <strong>Dr. Jeff Szymanski</strong>: Executive director of the{' '} 
                <a href='https://iocdf.org/'>International OCD Foundation</a> (IOCDF).
              </li>
              <li>
                <strong>Dr. Barbara Van Noppen</strong>: Expert in family-based
                treatments for OCD.
              </li>
              <li>
                <strong>Dr. Bradley Riemann</strong>: National leader in
                cognitive-behavioral therapy for OCD.
              </li>
              <li>
                <strong>Dr. Kyle King</strong>: Prominent clinician in ERP
                therapy for OCD.
              </li>
              <li>
                <strong>Dr. Marla Deibler</strong>: Founder of the <a href='https://www.cehcharlotte.com/'>Center for
                Emotional Health</a>, specializing in OCD.
              </li>
              <li>
                <strong>Dr. Debra Kissen</strong>: Expert in treating OCD using
                ERP and ACT.
              </li>
              <li>
                <strong>Dr. Robert Hudak</strong>: Researcher and psychiatrist
                specializing in severe OCD.
              </li>
              <li>
                <strong>Dr. Katia Moritz</strong>: Known for intensive OCD
                treatment programs.
              </li>
            </ol>
          </>
        }
      />
    </div>
  );
};

export default PostOCDExperts;
