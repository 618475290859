import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import Footer from './Footer';
import './Pricing.css';

const Pricing = () => {
  const plans = [
    {
      title: 'Free Plan',
      price: '$0/month',
      audience: 'For Individuals',
      features: [
        '✅ Trigger Tracker',
        '✅ Mood Tracker',
        '✅ SUDS Tracker',
        '✅ Exposure Hierarchy Builder',
        '✅ OCD Agenda',
        '✅ OCD News Feed',
        '✅ Historical data stored locally on your device',
      ],
    },
    {
      title: 'Registered User Free Plan',
      price: '$0/month',
      audience: 'For individuals who want their data to be securely saved',
      features: [
        '✅ Trigger Tracker',
        '✅ Mood Tracker',
        '✅ SUDS Tracker',
        '✅ Exposure Hierarchy Builder',
        '✅ OCD Agenda',
        '✅ OCD News Feed',
        '✅ Journaling',
        '✅ All Historical data securely saved',
        '✅ Accessible from multiple devices',
      ],
    },
    {
      title: 'Therapist Plan',
      price: '$29.99/month',
      trial: '30-day free trial (no payment info required)',
      audience: 'For Therapists',
      features: [
        '✅ Monitoring all your clients',
        '✅ Full free access for your clients',
        '✅ Export statistics to XLS',
        '✅ Add unlimited number of clients',
        '✅ Send notifications to clients',
        '✅ Create alerts for clients',
        '✅ Add webinars in OCD Agenda',
        '✅ All data securely saved',
        '✅ Accessible from multiple devices',
      ],
    },
  ];

  const handleFreePlanClick = () => {
    alert('You are already on the Free Plan.');
  };

  return (
    <>
      <Navbar />
      <div className='pricing-container'>
        <div className='pricing-plans'>
          {plans.map((plan, index) => (
            <div className='pricing-card' key={index}>
              <h2>{plan.title}</h2>
              <p className='price'>{plan.price}</p>
              {plan.title === 'Therapist Plan' ? (
                <Link to="/registerastherapist">
                  <button className='cta-button'>Choose Plan</button>
                </Link>
              ) : plan.title === 'Registered User Free Plan' ? (
                <Link to="/registerasclient">
                  <button className='cta-button'>Choose Plan</button>
                </Link>
              ) : (
                <button className='cta-button' onClick={handleFreePlanClick}>Choose Plan</button>
              )}
              {plan.trial && <p className='trial'>{plan.trial}</p>}
              <p className='audience'>{plan.audience}</p>
              <ul>
                {plan.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Pricing;