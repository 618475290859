import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAudio } from './AudioContext';
import './Navbar.css';
import logo from './logo-ocd-serenity-mini.png'; 

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);
  const { showAudioPlayer, handleMusicClick, stopMusic } = useAudio();

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className='navbar'>
      <div className='logo'>
        <Link to='/'>
          <img src={logo} alt='OCD Serenity Logo' className='logo-image' />
          
        </Link>
      </div>
      <div className={`burger ${menuOpen ? 'open' : ''}`} onClick={toggleMenu}>
        <div className='line1'></div>
        <div className='line2'></div>
        <div className='line3'></div>
      </div>
      <div className={`navbar-text ${menuOpen ? 'open' : ''}`}>
        <ul className='nav-links'>
          <li>
            <Link to='/ocdnews'>📰 OCD News Feed</Link>
          </li>
          <li>
            <Link to='/agenda'>📅 OCD Agenda</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px' }}>🛠️ Tools</span>
            <ul className='submenu'>
              <li>
                <Link to='/yale-brown'>📝 Yale-Brown OCD Assessment</Link>
              </li>
              <li>
                <Link to='/gad7'>📝 GAD-7 Anxiety Assessment</Link>
              </li>
              <li>
                <Link to='/phq9'>📝 PHQ-9 Depression Assessment</Link>
              </li>
              <li>
                <Link to='/trigger'>🎯 Trigger Tracker</Link>
              </li>
              <li>
                <Link to='/mood'>😊 Mood Tracker</Link>
              </li>
              <li>
                <Link to='/hierarchy-builder'>🚀 Exposure Hierarchy Builder</Link>
              </li>
              <li>
                <Link to='/tools'>⏱️ SUDS Tracker</Link>
              </li>
              <li>
                <Link to='/sudsimport'>📥 SUDS Import</Link>
              </li>
              <li>
                <Link to='/coupon'>🎟️ Reassurance Coupon</Link>
              </li>
              <li>
                <button
                  onClick={handleMusicClick}
                  style={{
                    background: 'none',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '10px',
                  }}
                  onMouseEnter={(e) => (e.target.style.backgroundColor = 'black')}
                  onMouseLeave={(e) => (e.target.style.backgroundColor = 'transparent')}
                >
                  🎵 Relaxing Sound
                </button>
              </li>
              <li>
                <Link to='/registerasclient'>✍️ Journaling (registered user)</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/dashboard'>📊 Dashboard</Link>
          </li>
          <li>
            <Link to='/50-influential-experts-shaping-ocd'>🗂️ Resources</Link>
          </li>
          <li>
            <Link to='/pricing'>💰 Pricing</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px' }}>🧭 How to use</span>
            <ul className='submenu'>
              <li>
                <Link to='/how-to-ocd'>🧠 If You Have OCD</Link>
              </li>
              <li>
                <Link to='/how-to-therapist'>🩺 You Are A Therapist</Link>
              </li>
              <li>
                <Link to='/get-a-demo'>🖥️ Our Demo</Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to='/ourvision'>🌈 Our Vision</Link>
          </li>
          <li className='menu-item'>
            <span style={{ padding: '10px', marginLeft: '100px' }}>👥 Login/Register</span>
            <ul className='submenu'>
              <li>
                <Link to='/login'>👤 Login</Link>
              </li>
              <li>
                <Link to='/registerasclient'>👤 Register</Link>
              </li>
              <li>
                <Link to='/registerastherapist'>🧑‍⚕️ Register as Therapist</Link>
              </li>
            </ul>
          </li>
        </ul>
        {showAudioPlayer && (
          <button onClick={stopMusic} style={{ position: 'absolute', top: '100px', right: '10px' }}>
            Stop Music
          </button>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
