import React, { useState, useEffect } from 'react';
import {
  doc,
  getDoc,
  setDoc,
  deleteDoc,
  collection,
  getDocs,
} from 'firebase/firestore';
import { db } from './config/firebaseConfig'; // Adjust the path to your Firebase config
import './ClearMockData.css';

const ClearMockData = ({ userId }) => {
  const [showMessage, setShowMessage] = useState(false);

  // Check if the mock data has already been cleared
  useEffect(() => {
    const checkMockDataStatus = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDoc = await getDoc(userDocRef);

        if (userDoc.exists() && userDoc.data().mockDataCleared) {
          setShowMessage(false); // Mock data has been cleared
        } else {
          setShowMessage(true); // Mock data still exists
        }
      } catch (error) {
        console.error('Error checking mock data status:', error);
        setShowMessage(true); // Default to showing the message if there is an error
      }
    };

    checkMockDataStatus();
  }, [userId]);

  // Function to clear mock data from specified collections
  const clearMockData = async () => {
    try {
      const collections = [
        'moodTracker',
        'ocdSuds',
        'ocdTriggers',
        'exposureHierarchy',
        'couponHistory',
      ];

      // Delete all documents for the user in each collection
      for (const collectionName of collections) {
        const collectionRef = collection(db, collectionName);
        const querySnapshot = await getDocs(collectionRef);

        // Filter for documents belonging to the specific user
        const userDocs = querySnapshot.docs.filter(
          docSnapshot => docSnapshot.id === userId
        );

        const deletePromises = userDocs.map(docSnapshot =>
          deleteDoc(doc(db, collectionName, docSnapshot.id))
        );

        await Promise.all(deletePromises);
      }

      // Update Firestore to indicate that mock data has been cleared
      const userDocRef = doc(db, 'users', userId);
      await setDoc(userDocRef, { mockDataCleared: true }, { merge: true });

      // Hide the message and button
      setShowMessage(false);

      // Reload the page
      window.location.reload();
    } catch (error) {
      console.error('Error clearing mock data:', error);
      alert('Failed to clear mock data. Please try again.');
    }
  };

  if (!showMessage) return null; // Do not render anything if the message should not be shown

  return (
    <div className='therapist-dashboard'>
      <div
        style={{
          alignItems: 'center', // Centers child elements horizontally
          justifyContent: 'center', // Centers child elements vertically
          textAlign: 'center',
          color: 'yellow',
        }}
      >
        <p>
          In the cards below, you will find mock data to explore and experience
          how each tool works. When you're ready to use the tools for yourself,
          click this button.
        </p>
        <button onClick={clearMockData} className='clear-mock-data-button'>
          Clear Mock Data
        </button>
      </div>
    </div>
  );
};

export default ClearMockData;
