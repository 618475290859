import React, { useState, useEffect } from 'react';
import {
  Bar,
  BarChart,
  ComposedChart,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Dot,
  ReferenceLine,
} from 'recharts';
import Navbar from './Navbar';
import Footer from './Footer';
import './Dashboard.css';
import initializeLocalStorage from './initializeLocalStorage';


const Dashboard = () => {
  const [allHistoricalData, setAllHistoricalData] = useState([]);
  const [storageUsedPercent, setStorageUsedPercent] = useState(0);
  const [dataTrigger, setDataTrigger] = useState([]);

  const [couponData, setCouponData] = useState([]);
  const isValidData = couponData && couponData.length > 0;

  // Fetch coupon data
  useEffect(() => {
    initializeLocalStorage();
    const fetchCouponData = () => {
      try {
        // Retrieve the coupon data from localStorage
        const storedData = localStorage.getItem('couponHistory');

        if (storedData) {
          // Parse and set the coupon data if it exists
          setCouponData(JSON.parse(storedData));
        } else {
          console.log('No coupon data found.');
        }
      } catch (error) {
        console.error('Error fetching coupon data:', error);
      }
    };

    fetchCouponData();
  }, []);

  const graphData = couponData.map((entry, index) => ({
    date: `Week ${index + 1}`,
    coupons: entry.coupons,
  }));

  // Calculate statistics
  const totalCouponsGiven = couponData.reduce(
    (total, entry) => total + entry.coupons,
    0
  );
  const totalWeeksTracked = couponData.length;
  const averageCouponsPerWeek = totalWeeksTracked
    ? totalCouponsGiven / totalWeeksTracked
    : 0;
  const maxCouponsInAWeek = Math.max(...couponData.map(entry => entry.coupons));
  const minCouponsInAWeek = Math.min(...couponData.map(entry => entry.coupons));
  const lastWeekCoupons = couponData[totalWeeksTracked - 1]?.coupons || 0;

  // Function to load all historical data from localStorage
  const loadAllHistoricalData = () => {
    const storedData = [];

    // Loop through all items in localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);

      // Skip certain keys that are not SUDS data
      if (
        key === 'rituals' ||
        key === 'settings' ||
        key === 'ocdTriggers' ||
        key === 'exposureHierarchy' ||
        key === '__vercel_toolbar_injector' ||
        key === 'pusherTransportTLS' ||
        key === 'savedEmail' ||
        key === 'savedPassword' ||
        key === 'moodTracker' ||
        key === 'couponHistory' ||
        key === '__stripe_mid' ||
        key === '__stripe_sid' ||
        key === '_grecaptcha' ||
        key === 'initialized'
      ) {
        continue;
      }

      // Parse the data if it exists and is an array
      const data = JSON.parse(localStorage.getItem(key));

      if (data && Array.isArray(data)) {
        storedData.push({ trigger: key, data });
      }
    }

    setAllHistoricalData(storedData); // Update state with filtered data
  };

  const [rituals, setRituals] = useState([]);

  const handleDelete = trigger => {
    const confirmDelete = window.confirm(
      `Are you sure you want to delete data for ${trigger}?`
    );
    if (!confirmDelete) {
      return; // Exit the function if the user cancels
    }

    // Remove the relevant key from localStorage
    localStorage.removeItem(trigger);
    // Reload the page to reflect the changes
    window.location.reload();
  };

  // Function to calculate the trend, comparative, and progress summary for a given ritual's data
  const calculateSummary = ritualData => {
    let totalLevel = 0;
    let totalEntries = 0;
    let highestLevel = 0;
    let lowestLevel = 100; // Assuming levels are between 0 and 100

    ritualData.data.forEach(dataset => {
      dataset.forEach(entry => {
        totalLevel += entry.level;
        totalEntries++;
        if (entry.level > highestLevel) highestLevel = entry.level;
        if (entry.level < lowestLevel) lowestLevel = entry.level;
      });
    });

    const avgLevel = totalEntries > 0 ? totalLevel / totalEntries : 0;

    // Calculate trend: Compare first session's average with the last session's average
    const firstSession = ritualData.data[0];
    const lastSession = ritualData.data[ritualData.data.length - 1];
    const firstAvg =
      firstSession.reduce((sum, entry) => sum + entry.level, 0) /
      firstSession.length;
    const lastAvg =
      lastSession.reduce((sum, entry) => sum + entry.level, 0) /
      lastSession.length;

    const trend =
      lastAvg > firstAvg ? 'up' : lastAvg < firstAvg ? 'down' : 'stable';

    // Comparative analysis
    const previousSession =
      ritualData.data.length > 1
        ? ritualData.data[ritualData.data.length - 2]
        : null;
    let comparison = 'no change'; // If there is no previous session

    if (previousSession) {
      const previousAvg =
        previousSession.reduce((sum, entry) => sum + entry.level, 0) /
        previousSession.length;
      comparison = lastAvg > previousAvg ? 'regression' : 'improvement';
    }

    // Progress percentage: How much the anxiety level has improved since the first session
    const progressPercentage = ((firstAvg - lastAvg) / firstAvg) * 100;

    // Check for milestones (e.g., anxiety level below a threshold or consistent improvement)
    const milestoneReached =
      lastAvg < 40 || (ritualData.data.length >= 5 && trend === 'down');

    return {
      avgLevel: avgLevel.toFixed(2),
      trend: trend,
      highestLevel,
      lowestLevel,
      comparison,
      progressPercentage: progressPercentage.toFixed(2),
      milestoneReached,
    };
  };

  const deleteData = () => {
    // Get all keys from localStorage
    const keys = Object.keys(localStorage);

    // Iterate over the keys and remove all except 'initialized'
    keys.forEach(key => {
      if (key !== 'initialized') {
        localStorage.removeItem(key);
      }
    });
    window.location.reload();
  };

  // Function to export localStorage data
  const exportData = () => {
    const localStorageData = {};
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      localStorageData[key] = localStorage.getItem(key);
    }
    const dataStr =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(localStorageData));
    const downloadAnchorNode = document.createElement('a');
    downloadAnchorNode.setAttribute('href', dataStr);
    downloadAnchorNode.setAttribute('download', 'ocdserenity_data.json');
    document.body.appendChild(downloadAnchorNode); // required for Firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
    alert('All data has been exported');
  };

  // Function to import localStorage data
  const importData = event => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = function (e) {
      const importedData = JSON.parse(e.target.result);
      for (const key in importedData) {
        localStorage.setItem(key, importedData[key]);
      }
      loadAllHistoricalData(); // Reload data after importing
    };
    reader.readAsText(file);
    alert('All data has been imported');
    window.location.reload(); // Refresh the page
  };

  // Function to calculate the percentage of localStorage used
  const calculateStorageUsage = () => {
    let totalSize = 0;

    // Loop through each item in localStorage
    for (let i = 0; i < localStorage.length; i++) {
      const key = localStorage.key(i);
      const value = localStorage.getItem(key);

      // Calculate size of each key-value pair using Blob to account for actual byte size
      const keySize = new Blob([key]).size;
      const valueSize = new Blob([value]).size;

      totalSize += keySize + valueSize;
    }

    // Estimate 5MB (typical browser limit for localStorage)
    const maxStorageSize = 5 * 1024 * 1024; // 5MB
    const usedPercentage = (totalSize / maxStorageSize) * 100;

    setStorageUsedPercent(usedPercentage.toFixed(2));
  };

  // Load all historical data on component mount
  useEffect(() => {
    loadAllHistoricalData();
    calculateStorageUsage();
    setDataTrigger(loadDataFromLocalStorage());
  }, []);

  // Custom function to style dots based on anxiety level
  const CustomDot = props => {
    const { cx, cy, payload } = props;
    let fill = 'lightblue';
    let r = 2;

    if (payload.level >= 80) {
      fill = 'red'; // High anxiety
      r = 4;
    } else if (payload.level <= 20) {
      fill = 'green'; // Low anxiety
      r = 4;
    }

    return <Dot cx={cx} cy={cy} r={r} fill={fill} />;
  };

  // Load Trigger Tracker Statistics
  const loadDataFromLocalStorage = () => {
    // Retrieve the data from localStorage
    const storedData = localStorage.getItem('ocdTriggers');

    // Parse the data if it exists, otherwise return an empty array
    return storedData ? JSON.parse(storedData) : [];
  };

  const distressLevels = {
    '😄': 1,
    '🙂': 2,
    '😐': 3,
    '😟': 4,
    '😢': 5,
  };

  // Invert distressLevels for Y-axis tick formatting (mapping level back to emoji)
  const distressEmojis = Object.fromEntries(
    Object.entries(distressLevels).map(([emoji, level]) => [level, emoji])
  );

  useEffect(() => {
    const loadedData = loadDataFromLocalStorage();
    setDataTrigger(loadedData);
  }, []);

  // Calculate average distress level
  const averageDistress =
    dataTrigger.length > 0
      ? dataTrigger.reduce(
          (sum, entry) => sum + distressLevels[entry.distress],
          0
        ) / dataTrigger.length
      : 0;

  /// Prepare data for the chart with dynamic average calculation
  const chartData = dataTrigger.reduce((acc, entry, index) => {
    const distressLevel = distressLevels[entry.distress] || 0; // Get the distress level
    const total = acc.reduce((sum, data) => sum + data.distressLevel, 0); // Calculate total distress level
    const averageDistress = ((total + distressLevel) / (index + 1)).toFixed(2); // Calculate average

    acc.push({
      entryNumber: index + 1, // Entry number starts from 1
      distressLevel,
      averageDistress, // Include the average distress level for the current entry
    });

    return acc;
  }, []);

  const triggerFrequency = dataTrigger.reduce((acc, entry) => {
    acc[entry.trigger] = (acc[entry.trigger] || 0) + 1;
    return acc;
  }, {});

  // Get the top 3 most common triggers
  const topCommonTriggers = Object.entries(triggerFrequency)
    .sort(([, a], [, b]) => b - a) // Sort by frequency
    .slice(0, 3) // Get top 3
    .map(([trigger]) => trigger); // Extract triggers

  // Calculate frequency of each compulsion
  const compulsionFrequency = dataTrigger.reduce((acc, entry) => {
    acc[entry.compulsion] = (acc[entry.compulsion] || 0) + 1;
    return acc;
  }, {});

  // Get top 3 most frequent compulsions
  const sortedCompulsions = Object.entries(compulsionFrequency)
    .sort(([, a], [, b]) => b - a) // Sort by frequency
    .slice(0, 3); // Get top 3

  const oneWeekAgo = new Date();
  oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

  const recentData = dataTrigger.filter(
    entry => new Date(entry.date) >= oneWeekAgo
  );
  const averageDistressLast7Days =
    recentData.length > 0
      ? recentData.reduce(
          (sum, entry) => sum + distressLevels[entry.distress],
          0
        ) / recentData.length
      : 0;

  const totalCompulsions = dataTrigger.filter(entry => entry.compulsion).length;
  const totalReliefs = dataTrigger.filter(entry => entry.relief).length;
  const compulsionReliefRatio =
    totalCompulsions > 0 ? (totalReliefs / totalCompulsions).toFixed(2) : 0;

  // Months to display (12 months)
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  // Emoji map for moods
  const moodEmojis = {
    1: '😊',
    2: '🙂',
    3: '😟',
    4: '😫',
  };

  const [moodData] = useState(() => {
    const savedData = localStorage.getItem('moodTracker');
    return savedData ? JSON.parse(savedData) : {};
  });

  useEffect(() => {
    localStorage.setItem('moodTracker', JSON.stringify(moodData));
  }, [moodData]);

  // Render the grid with days and months
  const renderGrid = () => {
    return months.map((month, monthIndex) => (
      <div key={month} className='month-row'>
        {/* Month label */}
        <div className='month-label'>{month}</div>
        <div className='days-row'>
          {Array.from({ length: 31 }, (_, day) => (
            <div
              key={day + 1}
              className={`grid-cell mood-${
                moodData[`${monthIndex}-${day + 1}`] || 0
              }`} // Apply mood class
            >
              {moodEmojis[moodData[`${monthIndex}-${day + 1}`]] || ''}
            </div>
          ))}
        </div>
      </div>
    ));
  };

  // Calculate percentages for each emoji
  const calculateEmojiPercentages = () => {
    const totalEntries = Object.values(moodData).filter(
      value => value !== null
    ).length;
    if (totalEntries === 0) return {};

    const emojiCounts = Object.values(moodData).reduce((counts, mood) => {
      counts[mood] = (counts[mood] || 0) + 1;
      return counts;
    }, {});

    // Calculate percentage for each emoji
    const emojiPercentages = {};
    for (const mood in moodEmojis) {
      const count = emojiCounts[mood] || 0;
      emojiPercentages[mood] = ((count / totalEntries) * 100).toFixed(1);
    }

    return emojiPercentages;
  };

  const emojiPercentages = calculateEmojiPercentages();

  // Exposure Hierarchy Builder

  const getFromLocalStorage = () => {
    const data = localStorage.getItem('exposureHierarchy');
    return data ? JSON.parse(data) : [];
  };

  const [exposures] = useState(getFromLocalStorage());

  // Calculate statistics
  const totalExposures = exposures.length;
  const completedExposures = exposures.filter(
    exposure => exposure.completed
  ).length;
  const incompleteExposures = totalExposures - completedExposures;
  const completionRate = totalExposures
    ? ((completedExposures / totalExposures) * 100).toFixed(1)
    : 0;

  const averageDistressLevel = exposures.length
    ? (
        exposures.reduce(
          (sum, exposure) => sum + Number(exposure.distress),
          0
        ) / exposures.length
      ).toFixed(1)
    : 0;

  const distressReductionOverTime = exposures
    .filter(exposure => exposure.completed && exposure.initialDistress)
    .reduce(
      (sum, exposure) => sum + (exposure.initialDistress - exposure.distress),
      0
    );

  return (
    <>
      <div class='mywraper'>
        <Navbar />
        <main>
          <div className='button-export-import'>
            <div className='storage-usage'>
              <strong>Storage Usage:</strong> 💾 {storageUsedPercent}%
            </div>
            <button onClick={exportData}>📤 Export Data</button>
            <input
              type='file'
              accept='.json'
              onChange={importData}
              style={{ display: 'none' }}
              id='importFile'
            />
            <label htmlFor='importFile' className='import-button-d'>
              📥 Import Data
            </label>
            <button
              onClick={deleteData}
              style={{
                backgroundColor: 'red',
              }}
            >
              Delete All Data
            </button>
          </div>

          {/* ************************************************************************** */}
          {/* ***************** CHECK IF THERE ARE STAT TO DISPLAY ********************* */}
          {/* ************************************************************************** */}
          {(() => {
            if (
              !(
                exposures.length > 0 ||
                Object.values(moodData).length > 0 ||
                dataTrigger.length > 0 ||
                allHistoricalData.length > 0 ||
                isValidData
              )
            ) {
              return (
                <div className='dashboard'>
                  <p>
                    No data to display! Use the trackers to display here your
                    statistics.
                  </p>
                </div>
              );
            }
          })()}

          {isValidData ? (
            <div className='dashboard'>
              <h2 className='dashboard-title'>Reassurance Coupon Tracker</h2>
              {/* Coupon Graph */}
              <div className='coupon-graph'>
                <h3>Reassurance Coupons Given</h3>
                <ResponsiveContainer width='100%' height={300}>
                  <BarChart data={graphData}>
                    <CartesianGrid strokeDasharray='3 3' />
                    <XAxis dataKey='date' />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey='coupons' fill='#8884d8' />
                  </BarChart>
                </ResponsiveContainer>
              </div>

              {/* Statistics */}
              <div
                className='container-trigger'
                style={{
                  maxWidth: '400px',
                  borderRadius: '8px',
                  backgroundColor: '#1e1e1e',
                  boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                  marginTop: '0px',
                }}
              >
                <p>
                  <strong>Total Coupons Given:</strong> {totalCouponsGiven}
                </p>
                <p>
                  <strong>Average Coupons Per Week:</strong>{' '}
                  {averageCouponsPerWeek.toFixed(2)}
                </p>
                <p>
                  <strong>Maximum Coupons in a Week:</strong>{' '}
                  {maxCouponsInAWeek}
                </p>
                <p>
                  <strong>Minimum Coupons in a Week:</strong>{' '}
                  {minCouponsInAWeek}
                </p>
                <p>
                  <strong>Total Weeks Tracked:</strong> {totalWeeksTracked}
                </p>
                <p>
                  <strong>Last Week's Coupons:</strong> {lastWeekCoupons}
                </p>
              </div>
            </div>
          ) : (
            <></>
          )}

          {exposures.length > 0 && (
            <div className='dashboard'>
              <h2 className='dashboard-title'>Exposure Hierarchy Builder</h2>
              <div className='statistics-grid'>
                <div className='statistics-item'>
                  <p>
                    <strong>Total Number of Exposures:</strong>
                    <br />
                    <br />
                    <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                      {' '}
                      {totalExposures}
                    </span>
                  </p>
                </div>
                <div className='statistics-item'>
                  <p>
                    <strong>Completed vs. Incomplete Exposures:</strong>
                    <br />
                    <br />
                    <span style={{ fontSize: '25px', fontWeight: 'bold' }}>
                      {completedExposures} vs {incompleteExposures}
                    </span>
                  </p>
                </div>
                <div className='statistics-item'>
                  <p>
                    <strong>Average Distress Level:</strong>
                    <br />
                    <br />
                    <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                      {' '}
                      {averageDistressLevel}
                    </span>
                  </p>
                </div>
                <div className='statistics-item'>
                  <p>
                    <strong>Distress Reduction Over Time:</strong>
                    <br />
                    <br />
                    <span style={{ fontSize: '60px', fontWeight: 'bold' }}>
                      {distressReductionOverTime}
                    </span>
                  </p>
                </div>
                <div className='statistics-item'>
                  <p>
                    <strong>Exposure Completion Rate:</strong>
                    <br />
                    <br />
                    <br />
                    <span style={{ fontSize: '30px', fontWeight: 'bold' }}>
                      {' '}
                      {completionRate}%
                    </span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {Object.values(moodData).length > 0 && (
            <div className='dashboard'>
              <h2 className='dashboard-title'>Mood Tracker</h2>
              {/* Display emoji percentages */}
              <div className='emoji-percentages'>
                {Object.keys(moodEmojis).map(mood => (
                  <div key={mood}>
                    {moodEmojis[mood]}: {emojiPercentages[mood] || 0}%
                  </div>
                ))}
              </div>
              <div className='container1-mood'>
                <div className='calendar-grid'>{renderGrid()}</div>
              </div>
            </div>
          )}

          {dataTrigger.length > 0 && (
            <div className='dashboard'>
              <h2 className='dashboard-title'>Trigger Tracker</h2>
              <div
                className='container-trigger'
                style={{
                  maxWidth: '400px',
                  borderRadius: '8px',
                  backgroundColor: '#1e1e1e',
                  boxShadow: '0 20px 20px rgba(0, 0, 0, 0.1)',
                }}
              >
                <p>
                  <strong>Distress Level Trend (Last 7 Days):</strong>{' '}
                  {averageDistressLast7Days.toFixed(1)}/5
                </p>
                <p>
                  <strong>Compulsion to Relief Ratio:</strong>{' '}
                  {compulsionReliefRatio}
                </p>

                <p>
                  <strong>Top 3 Most Common Triggers:</strong>
                </p>
                <ol>
                  {topCommonTriggers.length > 0 ? (
                    topCommonTriggers.map((trigger, index) => (
                      <li key={index}>{trigger}</li>
                    ))
                  ) : (
                    <li>No triggers recorded.</li>
                  )}
                </ol>
                <p>
                  <strong>Top 3 Most Frequent Compulsions:</strong>
                </p>
                <ol>
                  {sortedCompulsions.map(([compulsion, frequency]) => (
                    <li key={compulsion}>{compulsion}</li>
                  ))}
                </ol>
              </div>
              <div className='ritual-graph-trigger'>
                <h3>Historical Distress Levels</h3>
                <p>
                  <strong>Average Distress Level:</strong>{' '}
                  {averageDistress.toFixed(1)}/5
                </p>
                {chartData.length > 0 ? (
                  <ResponsiveContainer width='100%' height={450}>
                    <ComposedChart data={chartData}>
                      <CartesianGrid strokeDasharray='3 3' />
                      <XAxis dataKey='entryNumber' />{' '}
                      {/* X-axis shows entry numbers */}
                      <YAxis
                        ticks={[0, 1, 2, 3, 4, 5]}
                        tickFormatter={tick => distressEmojis[tick] || tick}
                        domain={[0, 5]}
                      />
                      <Tooltip />
                      <Legend />
                      <Bar dataKey='distressLevel' fill='#8884d8' />
                      <Line
                        type='monotone'
                        dataKey='averageDistress'
                        stroke='#82ca9d'
                        strokeWidth={2}
                      />
                    </ComposedChart>
                  </ResponsiveContainer>
                ) : (
                  <p>No distress data available.</p>
                )}
              </div>
            </div>
          )}

          {allHistoricalData.length > 0 ? (
            <div className='dashboard'>
              <h2 className='dashboard-title'>SUDS Tracker</h2>
              {allHistoricalData.map((ritualData, index) => {
                const summary = calculateSummary(ritualData); // Get the summary for this ritual
                return (
                  <div key={index} className='ritual-graph'>
                    <h3>
                      Recorded anxiety levels for{' '}
                      <span className='fear-color'>{ritualData.trigger}</span>
                    </h3>
                    <div className='responsive-wrapper'>
                      <ResponsiveContainer width='100%' height={300}>
                        <LineChart>
                          <CartesianGrid strokeDasharray='3 3' />
                          <YAxis ticks={[0, 20, 40, 60, 80, 100]} />
                          <XAxis
                            type='number'
                            dataKey='time'
                            tickFormatter={value => `${value} min`}
                            label={{
                              value: 'Time (min)',
                              position: 'insideBottomRight',
                              offset: -5,
                            }}
                          />
                          <YAxis
                            domain={[0, 100]}
                            label={{
                              value: 'Anxiety Level',
                              angle: -90,
                              position: 'insideLeft',
                            }}
                          />
                          <Tooltip />
                          <Legend />
                          <Line
                            type='monotone'
                            dataKey='level'
                            stroke='#8884d8'
                            strokeWidth={4}
                          />
                          <ReferenceLine y={80} stroke='red' />
                          <ReferenceLine y={20} stroke='green' />
                          {ritualData.data.map((dataset, datasetIndex) => (
                            <Line
                              key={datasetIndex}
                              type='monotone'
                              data={dataset}
                              dataKey='level'
                              stroke={`hsl(${
                                (datasetIndex * 60) % 360
                              }, 100%, 50%)`}
                              name={`${datasetIndex + 1}`}
                              dot={<CustomDot />}
                              activeDot={{ r: 6 }}
                            />
                          ))}
                        </LineChart>
                      </ResponsiveContainer>
                    </div>

                    {/* Trend, Comparative, and Progress summary for this ritual */}
                    <div className='trend-summary'>
                      <p>
                        <strong>Average Anxiety Level:</strong>{' '}
                        {summary.avgLevel} <br />
                        <br />
                        <strong>Trend:</strong>
                        <span className={`trend-${summary.trend}`}>
                          {summary.trend === 'up'
                            ? ' 📈 increased'
                            : summary.trend === 'down'
                            ? ' 📉 decreased'
                            : ' ⚖️ remained stable'}
                        </span>
                      </p>
                      <p>
                        <strong>Comparative Analysis: </strong>
                        {summary.comparison}
                      </p>
                      <p>
                        <strong>Highest Level:</strong> {summary.highestLevel} |{' '}
                        <strong>Lowest Level:</strong> {summary.lowestLevel}
                      </p>
                      <p>
                        <strong>Progress: </strong>
                        {summary.progressPercentage}% improvement
                        {summary.progressPercentage > 0 && (
                          <div className='progress-bar-container'>
                            <div
                              className='progress-bar'
                              style={{
                                width: `${
                                  summary.progressPercentage > 0
                                    ? summary.progressPercentage
                                    : 0
                                }%`,
                                backgroundColor:
                                  summary.progressPercentage >= 0
                                    ? 'green'
                                    : 'red',
                              }}
                            ></div>
                          </div>
                        )}
                      </p>
                      {summary.milestoneReached && (
                        <p className='milestone'>
                          🎉 <strong>Milestone Achieved!</strong>
                          <br /> You've consistently recorded lower anxiety
                          levels or achieved a goal!
                        </p>
                      )}
                      <button
                        className='clear-btn'
                        onClick={() => handleDelete(ritualData.trigger)}
                        style={{ marginLeft: '85%' }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : null}
        </main>
        <Footer />
      </div>
    </>
  );
};

export default Dashboard;
